import React from "react";
import Layout from "./../components/Layout/Layout";
import ManifestoBody from "./../components/Manifesto/ManifestoBody";

const ManifestoPage = () => (
  <Layout>
    {/*<ManifestoImage />*/}
    <ManifestoBody />
  </Layout>
);
export default ManifestoPage;
