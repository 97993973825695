import styled from "styled-components";
import theme from "./../styles/theme";
import CaseHeaderImage from "./../images/caseheader.jpg";

export const StyledCarouselImage = styled.div`
  display: flex;
  height: calc(100vh - 80px);
  width: 60%;
  align-items: center;
  justify-content: center;
  background: url("https://d2c7ipcroan06u.cloudfront.net/wp-content/uploads/2020/02/IMG_3307-scaled.jpg");
  background-size: cover;
  transition: 0.4s cubic-bezier(1, -1, 0, 2);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0 100%);
  position: relative;
`;

export const StyledHeadline = styled.div`
  background: ${theme.colors.white};
  padding: 2rem 5rem;
  width: 400px;
  text-align: left;
  width: 40%;

  h3 {
    font-size: 48px;
    color: ${theme.colors.primary};
  }
`;
export const CarouselItem = styled.div`
  display: flex !important;
  align-items: center;
`;

export const FlexImageTextContainer = styled.div`
  display: flex;
  padding: 5rem 0;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`;
export const FlexImageTextHighlight = styled.div`
  display: flex;
  align-items: center;
  margin: 5rem 0;
  background: ${({ color }) => color};
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`;

export const FlexTextContainer = styled.div`
  flex: 5;
  padding: 0 2rem;
`;
export const FlexTextHightlight = styled.div`
  flex: 4;
  padding: 3rem 2rem;
`;
export const FlexImageContainer = styled.div`
  flex: 5;
  padding: 0 0rem;
`;
export const FlexTextHeaderSmall = styled.h4`
  font-size: 22px;
  font-weight: 600;
`;
export const FlexTexSmall = styled.h4`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
`;

export const FlexTextHeader = styled.h2`
  font-size: 34px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 600;
`;

export const FlexImageHighlight = styled.div`
  background: url(${({ src }) => `'${src}'`});
  align-self: stretch;
  flex: 5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const CaseHeaderContainer = styled.div`
  background-image: ${({ image }) => `linear-gradient(
      to top,
      rgba(245, 246, 252, 0.1),
      rgba(0, 0, 0, 0.3)
    ),
    url(${image || CaseHeaderImage})`};
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
`;

export const CaseHeader = styled(CaseHeaderContainer)``;

export const CaseMainHeading = styled.h1`
  font-size: 45px;
  font-weight: 600;
  padding: 2rem 5rem;
  margin: 0;
  background: #fff;
  color: #000;
`;

export const CaseOverviewContainer = styled.div`
  padding: 10rem 0;
`;

export const CaseOverviewHeading = styled.h3`
  font-family: "Poppins";
  font-size: 32px;
`;

export const CaseText = styled.h1`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.75;
  font-family: "Poppins";
  color: #000;
`;
export const MediumContainer = styled.div`
  max-width: 800px;
  margin: ${({ nomargin }) => (nomargin ? "0" : "auto")};
  @media screen and (max-width: 991px) {
    max-width: 700px;
    padding: 2rem;
  }
`;
