import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import Microlink from "@microlink/react";
import CaseOverview from "../components/Cases/CaseOverview";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Lynching three Dalit youth for falling in  love with a Maratha girl in Sonai 
    </CaseHeader>
    <CaseOverview
      title={`
      Lynching three Dalit youth for falling in  love with a Maratha girl in Sonai 
        `}
      metadata={"1 Jaunary 2013 • Ahmednagar Maharashtra • Compiled By ACJP "}
    >
      <CaseText>
        <br />
        Date:01/01/2013
        <br/><br/>
        Place:  Sonai Village , Ahmednagar district,Maharashtra, India.
        <br/><br/>
        Three Scheduled Caste youths Mr. Sachin Gharu (24), Mr Sandeep Thanvar (25) and Mr  Rahul Kandare (20), were called at home to clean septic tank and all were  brutally murdered and their mutilated body parts scattered in a septic tank and a dried-up well on 1st January 2013 evening to teach lesson to Mr Sachin Gharu who fell in love with a Maratha girl studying at Trimurti Pavan Pratishthan School and College in Ahmednagar’s Nevasa Phata and also killing his two innocent collegues to wipe out evidence and witnesses.All three were  belonging to Scheduled Caste Bhangi Mehtar Community.Mr Sachin Gharu was an employee of the school where the Maratha girl was a student.
        The six accused of the crime include Ramesh Darandale (43), Prakash Darandale (38), Ragunath Darandale alias ‘Popat’ (52), Ganesh alias Praveen Darandale (23), Sandeep Kurhe (37) and Ashok Phalke (44). Ramesh, Prakash and Popat are brothers, while Sandeep Kurhe is a relative of the Darandales.All were announced capital punishmrnt by the sessions court at Nashik.
        <br/><br/>
        "It was the opinion of the court that caste prejudices ought to checked like one curbs the spread of a malignant disease. Persons who arrogantly flaunt the ‘superiority’ of their caste by keeping up an unfair status quo through means of violence and other means of spreading hatred should not be allowed to roam scot-free,” said Special Public Prosecutor (SPP) Ujjwal Nikam, speaking outside the courtroom after the verdict.
        <br/><br/>
        Earlier, Advocate Nikam, presenting his concluding arguments, had urged the court to award the death penalty to the six convicted for the crime based on 13 parameters which drew on the evidence after cross-examining more than 50 witnesses.
        <br/><br/>
        “The murders are a blot on humanity and a cruel reminder of prevailing caste prejudices. A conspiracy was hatched by the Darandales (who belong to the Maratha community) and the plan was carried out with incredible brutality just because a boy from ‘backward’ caste fell in love with a girl from a ‘higher’ caste and the duo were set to marry,” Mr. Nikam had said.
        <br/><br/>
        All three youths, who were their respective families’ sole breadwinners, were hacked to death by the Darandales and their relatives in the latter’s village in Sonai between 3:30 and 8 p.m. on January 1, 2013.
        <br/><br/>
        The trigger for the gruesome crime was because Sachin Gharu reportedly fell in love with the daughter of one of the Darandales.
        <br/><br/>
        According to the FIR lodged at Sonai police station, Sachin and his colleagues, who worked as sweepers, were summoned by the Darandale family to their home on the fateful evening of January 1 ,2013 to clean their septic tank.
        <br/><br/>
        After committing the horrific murders, Ramesh and Prakash Darandale then contacted the police and reported that Sandeep allegedly drowned while cleaning the septic tank.
        <br/><br/>
        This version roused the suspicions of the police when they pulled out the body Sandeep — a six-footer — from a tank that barely contained 2 ft. water.
        <br/><br/>
        On further grilling, Ramesh Darandale later changed his story and alleged that Sachin and Rahul murdered Sandeep, dumped his body in the tank and fled.
        <br/><br/>
        On probing further, the police later discovered Sachin’s decapitated body, with his limbs severed from the trunk from a nearby well.Rahul’s mutilated body was later found with severe injury marks. 
        <br/><br/>
        Fearing attack on their life ,relatives and family members demanded that the case be heard outside of Ahmednagar district.
        Therefore the case was shifted to Nashik Distt.
        Bombay Highcourt also confirmed capital death penalty to all accused in the caste murder case.
        <br/><br/>
      </CaseText>
      <br />
      <CaseText>
        You can find the original article below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.thehindu.com/news/national/other-states/2013-sonai-murders-nashik-court-awards-death-sentence-to-six/article22478786.ece"
        size="small"
      />
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://scroll.in/latest/865783/2013-sonai-murders-nashik-court-awards-death-sentence-to-six-for-the-murder-of-3-dalit-youths"
        size="small"
      />
      <div style={{ height: "24px" }} />

    </MediumContainer>
  </Layout>
);
export default Case;
