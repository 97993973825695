import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";
const Page = () => (
  <Layout>
    <CaseHeader
      image={
        "https://d2c7ipcroan06u.cloudfront.net/wp-content/uploads/2020/02/IMG_3307-scaled.jpg"
      }
    >
      Dalit boys beaten, caste abused and sodomized
    </CaseHeader>
    <CaseOverview
      title={`
            Dalit boys beaten, caste abused and sodomized to force EMI
            payment of EMI of a Motorcycle in Nagaur District of Rajasthan.
        `}
      metadata={"16th February 2020 • Nagaur • The Print"}
    >
      <CaseText>
        <br />
        Two dalit scheduled case cousins, Mr. Pannaram and Mr Visharam Nayak
        were severly beated, caste abused and sodomized with a screw driver
        dipped in petrol by Mr Hanuman Singh, an upper caste Rajput who is the
        owner of an Automobile agency. The cousins had reportedly bought a
        motorcycle on a monthly installment and had failed to pay the February
        EMI duly.
        <br />
        <br />
      </CaseText>

      <CaseText>
        The incident took place on the afternoon of Sunday, 16th February 2020
        when the cousins went to the Automobile Service Center in Nagaur. Mr
        Hanuman Singh, the prime accused, along with the six other people tied
        up, striped and physically abused the Nayak cousins. The victims were
        attacked by the group with a belt, and later sodomized with a petrol
        dipped screw driver. Threats were made to them for the payment of the
        February EMI in exchange for their release.
        <br />
        <br />
      </CaseText>

      <CaseText>
        The brothers, their family, and the scheduled caste population of the
        village is terrified by this incident. Upon arrest, Mr Singh and the
        others involved, filed a counter FIR to pressurize the victims to
        withdraw the original FIR. The victims fear for their lives and have
        been provided police protection on request. The report of this incident
        was further escalated in the Rajasthan State Assembly by the scheduled
        caste representatives under the The Scheduled Castes and Tribes
        (Prevention of Atrocities) Act, 1989 .
        <br />
        <br />
      </CaseText>
      <CaseText> You can find the original article below: </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <Microlink
        url="https://theprint.in/india/rajasthan-village-says-assault-on-dalits-a-first-but-nagaur-is-infamous-for-caste-violence/369694/"
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Page;
