import React from "react";
import { Container, StyledHeading } from "./../styles/commonStyle";
import HighlightedContainer from "./HighlightedContainer";
const MainCase1 = () => (
  <div style={{ margin: "5rem 0" }}>
    <Container>
      <StyledHeading>Recent Cases</StyledHeading>
      <HighlightedContainer
        title="Lynching three Dalit youth for falling in  love with a Maratha girl"
        source="https://th.thgim.com/news/national/other-states/article22478783.ece/ALTERNATES/FREE_660/UJJALNIKAM"
      >
        Three Scheduled Caste youths Mr. Sachin Gharu (24), Mr Sandeep Thanvar (25) and Mr  Rahul Kandare (20), were called at home to clean septic tank and all were  brutally murdered and their mutilated body parts scattered in a septic tank and a dried-up well on 1st January 2013 evening to teach lesson to Mr Sachin Gharu who fell in love with a Maratha girl studying at Trimurti Pavan Pratishthan School and College in Ahmednagar’s Nevasa Phata and also killing his two innocent collegues to wipe out evidence and witnesses.All three were  belonging to Scheduled Caste Bhangi Mehtar Community.Mr Sachin Gharu was an employee of the school where the Maratha girl was a student.
        <br/><b>Courtesy: The Hindu</b>
      </HighlightedContainer>
    </Container>
  </div>
);

export default MainCase1;
