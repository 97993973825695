import React from "react";
import Layout from "./../components/Layout/Layout";
import AboutSectionMain from "./../components/AboutPage/AboutSection";
import Vision from "./../components/AboutPage/Vision";
import VisionNew from "./../components/AboutPage/VisionNew";
import WeBelieve from "./../components/AboutPage/WeBelieve";
import WhoWeAre from "./../components/AboutPage/WhoWeAre";
import WordFromFounder from "./../components/AboutPage/WordFromFounder";
import WordFromFounderMore from "../components/AboutPage/WordFromFounderMore";
import WhatWeDo from "./../components/AboutPage/WhatWeDo";

const AboutPage = () => (
  <Layout>
    <AboutSectionMain />
    <WeBelieve />
    <VisionNew />
    <WordFromFounder />
    <WordFromFounderMore />
    <WhoWeAre />
    <WhatWeDo />
  </Layout>
);
export default AboutPage;
