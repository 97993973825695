const theme = {
  colors: {
    primary: "#1111BD",
    darkprimary: "#000",
    secondary: "#672C49",
    lightprimary: "#FFF2DE",
    lightgrey: "#f0f0f0",
    white: "#fff",
    black: "#000",
    primary2: "#543665",
    mainfontcolor: "#3e3e3e",
  },
  shadows: {
    lightshadow: "2px 2px 15px rgba(122, 122, 122, 0.1)",
    darkshadow: "30px 40px 30px rgba(0, 0, 0, 0.1)",
  },
  borderRadius: "6px",
};

export default theme;
