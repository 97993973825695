import styled from "styled-components";
import { Layout } from "antd";
const { Footer } = Layout;
import { Link } from "gatsby";
import theme from "../styles/theme";

export const StyledFooter = styled(Footer)`
  background: ${theme.colors.black};
  color: ${theme.colors.white};
  padding-top: 5rem;
  padding-bottom: 5rem;
`;
export const FooterLeft = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr;
`;
export const FooterTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;
export const FooterLink = styled.div`
  font-size: 0.9rem;
  padding-top: 1rem;
  font-weight: 400;
`;

export const FooterRight = styled.div`
  min-width: 250px;
`;
export const Brand = styled(Link)`
  font-size: 34px;
  padding: 0rem 0;
  text-decoration: none;
  font-weight: 500;
  color: ${theme.colors.white};
`;

export const BrandSmall = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
