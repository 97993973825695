import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";
const Page = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.cnn.com/cnnnext/dam/assets/200929214839-02-gang-rape-protest-0929-restricted-exlarge-169.jpg"
      }
    >
      Indian woman from scheduled caste community dies after  gang-rape, and assault in Hathras, UP| Tag (News: CNN)
    </CaseHeader>
    <CaseOverview
      title={`
          Indian woman from scheduled caste community dies after  gang-rape, and assault in Hathras, UP| Tag (News: CNN).
        `}
      metadata={"14 September 2020 • Hathras UP • BBC"}
    >
      <CaseText>
        Date of Incident:14/09/2020
        <br />
        <br />
        Place: Bhulgarhi village, Chandpa police station, Distt- Hathras, Utterpradesh State
        <br />
        <br />
        Victim: Sister of Mr Sandeep Walmiki age 19 unmarried
        <br />
        <br />
        Accused: Four uppercaste non SC-ST men from same village- Bhulgarhi.
        <br />
        <br />
        Details of Incident:
        A most heinous and dastardly act of gang rape and grave injury and subsequent death of a  scheduled caste girl of 19 yrs age from  Bhulgarhi village of Hathras Distt in Utterpradesh State.
        The victim ,sister of Mr Sandeep Walmiki  was gang-raped by non  SC-ST uppercaste men from the  same village on 14th  Sept 2020 morning. She was strangulated  by rapists and they cut her tongue so that she  won't  be able narrate about gang rape on her own.
        <br />
        <br />
        She was admitted to Hathras Distt Hospital and from there  she was sent to Aligarh Hospital and Medical College at Aligarh. She was later on shifted to Safdarjung Hospital in Delhi on 28th Sept 2020 where she succumbed to her injuries and died on 29th September 2020. Her dead body was forcibly  cremated by Hathras Distt  police and administration in the late  night of 29th September 2020 which is early morning of 30th September 2020 at about 2.40am without the consent of the parents and other  family members of the victim girl.
        <br />
        <br />
        There is total negligence on the  part of Chandpa police station of Hathras Distt .They didn’t record FIR properly obviously under pressure from accused upper caste by the  the District Magistrate  of Hathras who is also the head of District Vigilance and Monitoring Committee responsible for implementatiom of the Atrocities Prevention Act 1989 and Rules 1995. He did not even  bother to immediately come and visit the victim in the hospital which is mandated for Distt Magistrate and Suptd of Police of every district as per POA Act and Rules  and  no proper treatment and no proper examination in Aligarh Hospital from the point of gang rape as the victim was often telling the doctors and those visiting in the hospital  that she was gang-raped. It was the duty of the District Magistrate and State Nodle officer and Addl.DGP-PCR to immediately visit the victim and provide all medical care by sending the victim to AIIMS Delhi or any better hospital in India or abroad similar to  Nirbhaya gangrape victim was sent to Singapore for treatment. If she had 
        received Nirbhaya like medical care,the victims life could have been saved.
        By not doing so, they have failed miserably  in their duty. This is a case of  willful neglect and dereliction of duty
        <br />
        <br />
        A scheduled case young girl was kidnapped in the afternoon in Hathras Distt in Utter Pradesh state while she was returning from her farm. She was abducted on way home take to secluded place raped and beaten to near death by breaking her backbone and cutting her tongue on 14th September 2020.
        <br />
        <br />
        While family started searching on way to the farm they found mother found her daughter unconscious and scarf tightly tied to her neck and barely alive. They rushed immediately to local district hospital and from there the case was referred to Aligarh Medical college and Hospital. She was not given proper treatment and shifted to Safdarjung Hospital in capital Delhi where she succumbed to injuries on 29th Sept 2020.Police and administration of the district Hathras brought the dead body to the Chandpa village of the victim and disposed of by burning the body without showing to the family and without their consent.

      </CaseText>

      <CaseText> You can find the original article below: </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <Microlink
        url="https://www.bbc.com/news/amp/world-asia-54351744"
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Page;
