import styled from "styled-components";
import theme from "./theme";
import { Button } from "antd";

export const StyledPrimaryButton = styled(Button)`
  border-radius: 5px;
  margin: ${({ margin }) => margin || "0px"};
  background: ${theme.colors.primary};
  color: ${theme.colors.lightprimary};
  border: 1px solid transparent;
  &:hover {
    background: ${theme.colors.darkprimary};
    color: ${theme.colors.lightprimary};
    box-shadow: ${theme.shadows.lightshadow};
    border: 1px solid ${theme.colors.darkprimary};
  }
`;

export const StyledSecondaryButton = styled(StyledPrimaryButton)`
  color: ${theme.colors.primary};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.primary};
`;
export const StyledLargePrimaryButton = styled(StyledPrimaryButton)`
  padding: 16px 32px;
  height: unset;
  font-size: 18px;
  line-height: 1;

  &:active,
  &:focus {
    background: ${theme.colors.darkprimary};
    color: ${theme.colors.lightprimary};
  }
`;
export const StyledLargeSecondaryButton = styled(StyledSecondaryButton)`
  padding: 16px 28px;
  height: unset;
  font-size: 16px;
  line-height: 1;

  &:active,
  &:focus {
    background: ${theme.colors.darkprimary};
    color: ${theme.colors.lightprimary};
  }
`;
export const Container = styled.div`
  padding: 0rem 1rem;
  margin: ${({ margin }) => (margin ? "5rem auto" : "0 auto")};
  position: relative;
  @media (min-width: 576px) {
    width: 500px;
    padding: 0 2rem;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 700px;
    padding: 0 2rem;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 900px;
    padding: 0 2rem;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 1080px;
    padding: 0 0rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${({ spaceBetween, spaceAround, center }) =>
    spaceBetween
      ? "space-between"
      : spaceAround
      ? "space-around"
      : center
      ? "center"
      : "left"};
  align-items: ${({ top, bottom }) =>
    top ? "flex-start" : bottom ? "flex-end" : "center"};

  @media screen and (max-width: 450px) {
    display: block;
  }
`;

export const StyledHeading = styled.div`
  font-size: 48px;
  font-weight: 600;
  color: ${({ color }) => color || theme.colors.black};
`;
export const StyledSubHeader = styled.div`
  font-size: 34px;
  font-weight: 600;
  color: ${({ color }) => color || theme.colors.black};
`;
export const StyledText = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 5px;
  color: ${({ color }) => color || theme.colors.black};
`;
