import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://images.hindustantimes.com/rf/image_size_630x354/HT/p2/2018/06/14/Pictures/students-union-from-vemula-protest-over-rohith_13390204-6fea-11e8-bbf6-b72314b60444.jpg"
      }
    >
      Dalit Woman and son, stripped, paraded naked through village, get Justice after 14 long years, Solapur, MH
    </CaseHeader>
    <CaseOverview
      title={`
          Dalit Woman and son, stripped, paraded naked through village, get Justice after 14 long years, Solapur, MH

        `}
      metadata={"13 March 2020 • Telgaon Solapur Maharashtra • ACJP "}
    >
      <CaseText>
        <br />
        <br />
        On 11th March, 2006, a Dalit woman, Ashabai Wanjare, and her child were beaten and forcefully paraded naked before the villagers of Telgaon Taluka -North Solapur, Maharashtra. Ashabai had previously complained to the police about illegal liquor shops in the village, and was on her way to the police station to follow up on her complaint when the incident occurred.
        <br />
        <br />
        The nine accused, including two women, were upper caste residents of the village who were infuriated by Ashabai’s complaint of their business. After the incident, Ashabai immediately lodged a complaint with the North Solapur police under SC-ST Prevention of Atrocities act 1989 and Indian Penal Code.  She was provided financial assistance for daily sustenance by the ACJP and Adv. Sanjeev Sadafule was appointed to fight her case in the court.
        <br />
        <br />
        Since the FIR was filed, Ashabai met with great difficulties as the accused attempted to apply pressure through political influence and local community leaders to withdraw the case. She was socially boycotted and daily wage work was made strictly unavailable to her in the village. She withstood all pressure tactics and won her case in the sessions court after 14 years of perseverance. The matter of caste atrocities against the SC-ST was also raised in the State Assembly and Parliament. 
        <br />
        <br />
        Acjp resolved to standby her in future battles in High court and Supreme Court.
        <br />
        <br />

      </CaseText>

    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url=""
        size="small"
      />      
    </MediumContainer>
  </Layout>
);
export default Case;
