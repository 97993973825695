import styled from "styled-components";
import { Layout } from "antd";
import theme from "./../styles/theme";
const { Content } = Layout;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledContent = styled(Content)`
  padding: 0 0px;
  margin-top: 80px;
  flex-grow: 1;
  background: ${theme.colors.white};
`;
