import React from "react";
import {
  Container,
  FlexContainer,
  StyledSubHeader,
  StyledText,
} from "../styles/commonStyle";
import Image1991 from "./images/image1991.jpeg";
import Image1993 from "./images/image1993.jpeg";
import Image1995 from "./images/image1995.jpeg";
import Image1996 from "./images/image1996.jpeg";
import Image2001 from "./images/image2001.jpeg";

import { StyledTextContainer } from "./ACJPatUNStyles";
const Article = [
  {
    id: 1,
    heading: "1991",
    text:
      "ACJP introduced Caste System and Practice of Untouchability  to United Nations bodies since 1991, like  Human Rights Council and its earlier organ. (CERD in 1996 had a detailed discussion during the India Report  which also helped UN to  focus on  the study  of Caste-based discrimination  in South Asia.)  ",
    image: Image1991,
  },
  {
    id: 2,
    heading: "1993",
    text:
      "World Conference on Human Rights at Vienna, Austria: In Prepcom Bangkok where all asia pacific ngos met,  passed the resolution :“ to condemn the practice of Caste System and Untouchability, which is crime against humanity, and Countries should eradicate it within 10 years time or face sanctions”. This was also passed in Planery Session in Vienna. In Vienna,many EU members assured us that this issue was long overdue to be discussed in UN and they would help us. ",
    image: Image1993,
  },
  {
    id: 3,
    heading: "1995",
    text:
      "UN World Summit on Social Development at Copenhagen, Denmark National News media gave a special attention to cover the caste system, practice of untouchability, child labour, bonded labour, Devadasi system through THE POLITICKEN, AND BERLINGSKE TIDENDE daily newspapers which educated the National and Global civil society with very positive impact. ",
    image: Image1995,
  },
  {
    id: 4,
    heading: "1996",
    text:
      " Treaty Body CERD Concluding Observations-Recommendations in (Article 31, CERD/C/304/Add.13dated 17/09/1996} on Human Rights Education to the Nation which states” India to start continuing  human rights education in line with Indian Constitution and UN Human Rights Instruments including Convention on Racial Discrimination. This should be aimed at eliminating high caste and low caste mentality.”",
    image: Image1996,
  },
  {
    id: 5,
    heading: "2001",
    text:
      "UN World Conference Against Racism at Durban , South Africa.“ ACJP was primarily responsible to bring the issue of Caste System and Discrimination in United Nations World Conference Against Racism. “ said Smita Narula -HRW group during  Durban. We had given many TV/Radio interviews on the Caste System and practice of Untouchability.Caste System was fully exposed at global level for the first time.",
    image: Image2001,
  },
];

const Archievements = () => {
  return (
    <div>
      <Container>
        <div style={{ padding: "5rem 0 5rem" }}>
          <StyledSubHeader>Achievements</StyledSubHeader>
          <FlexContainer top style={{ flexWrap: "wrap" }}>
            {Article.map(item => (
              <StyledTextContainer
                key={item.id}
                style={{ padding: "4rem 2rem " }}
              >
                <img
                  src={item.image}
                  width="100%"
                  style={{ margin: "10px 0" }}
                />
                <StyledSubHeader>{item.heading}</StyledSubHeader>
                <StyledText>{item.text}</StyledText>
              </StyledTextContainer>
            ))}
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default Archievements;
