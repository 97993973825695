import styled from "styled-components";
import theme from "./../styles/theme";

export const StyledMainContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;

export const StyledTextContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;

export const ChartExplainer = styled.div`
  padding: 3rem 4rem;
  color: ${theme.colors.white};
  background: #f3f3f3;
`;
