import React from "react";
import {
  Container,
  FlexContainer,
  StyledSubHeader,
} from "../styles/commonStyle";
import { Avatar } from "antd";
import { StyledMainContainer, QuoteContainer, Quote } from "./CommunityStyles";

const Article = [
  {
    id: "1",
    quote:
      " Some Quote from a member of the community should go here in this  box now.",
  },
  {
    id: "2",
    quote:
      " Some Quote from a member of the community should go here in this  box now.",
  },
  {
    id: "3",
    quote:
      " Some Quote from a member of the community should go here in this  box now.",
  },
];

const Testimonial = () => {
  return (
    <div>
      <Container>
        <div>
          <StyledMainContainer>
            <StyledSubHeader>Testimonials</StyledSubHeader>
          </StyledMainContainer>
          <FlexContainer top style={{ padding: "3rem 0" }}>
            {Article.map(item => (
              <Quote key={item.id}>
                <QuoteContainer>{item.quote}</QuoteContainer>
                <Avatar
                  size={64}
                  src={
                    "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  }
                />
              </Quote>
            ))}
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default Testimonial;
