import React from "react";
import Layout from "./../components/Layout/Layout";
import CasesMain from "../components/Cases/CasesMain";
import HomeCarousel from "../components/HomePage/HomeCarousel/HomeCarousel";
import MainCase1 from "../components/Cases/MainCase1";
import MainCase2 from "../components/Cases/MainCase2";
import OtherCases from "../components/Cases/OtherCases";
import MoreLinks from "../components/Cases/MoreLinks";

const Page = () => (
  <Layout>
    {/* <CasesMain /> */}
    <HomeCarousel />
    <MainCase1 />
    <MainCase2 />
    <OtherCases />
    <MoreLinks />
  </Layout>
);
export default Page;
