import React from "react";
import { Container, StyledHeading, StyledText } from "../styles/commonStyle";
import rapeCasesImg from "../images/atrocity_rape_cases.png";
const ReportsContainer2 = () => {
  return (
    <div>
      <Container style={{ padding: "5rem" }}>
        <img
          src={rapeCasesImg}
          width="100%"
          style={{ padding: "0rem 0 0rem" }}
        />
        <b>Line chart showing trend of rape cases against SC/ST between year 2009 to 2019 with clear separation of UPA(Congress lead) and NDA(BJP lead) government periods</b>
        <StyledHeading style={{ padding: "2rem 0 2rem" }}>
          Rapes | Report from 2009 - 2019
        </StyledHeading>
        <StyledText>
          This Rape chart of SC-ST women is indicative of increased numbers of rape of girls and women in India due vulnaribility of SC-ST women who are mostly daily wage farm workers or marginal labourer or labourer engaged in mines,stone queries or big farms like wheat or sugar cane farms.It is also indicative of the uppercaste male dominance mentality that nothing will happen if they  commit rape of SC-ST women and they can get away even if cought in the case and will get acquitted through their influence with police and judiciary who mostly belong to dominant castes.This also indicates increased awareness about provisions of the law against sexual violence and confidence in the Indian Constitution and hope for justice through legal means and also indicates increased awareness of right to live with dignity.
        </StyledText>
      </Container>
    </div>
  );
};

export default ReportsContainer2;
