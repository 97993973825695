import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Caste Humiliation and atrocitiy on  a scheduled caste PhD Asst Professor in IIT Kanpur, Utterpradesh,India.
    </CaseHeader>
    <CaseOverview
      title={`
          Caste Humiliation and atrocitiy on  a scheduled caste PhD Asst Professor in IIT Kanpur, Utterpradesh,India.

        `}
      metadata={"Jan 2020 • Kanpur UP • Compiled By ACJP "}
    >
      <CaseText>
      <br />
      <br />
      Victim:Prof (Dr) Subramanian Saderla Asst Professor, Auronotical Sciences,Indian Institute of Technology, Kanpur, Utterpradesh.
      <br />
      <br />
      Indian Institute of Technology Kanpur is a one of the leading technological Institute of the Govt of India. It is expected that it is free from all prejudices of caste creed and gender.But it is a myth.
      <br />
      <br />
      Recently a scheduled caste Assistant Professor of Aeronautical sciences Dr S.Saderla was humiliated on caste ground.  Mr.Saderla a  M. Tech and Ph.D. in Aeronautical sciences and belonging to  scheduled caste community  appeared for the interview on a vacant reserved post in Jan.2018 under special recruitment drive and during seminar prior to interview ,he was humiliated and cursed by his fellow four  Professors as they knew that Dr. Saderla  is belonging to scheduled caste community. One of the four professors created a temporary email  and circulated a mail informing all students and faculty members that a curse has struck IIT Kanpur in the form of Dr. Saderla and it happens every after ten years. Dr. Saderla had  submitted a written complaint for action on four castiest professors. Department conducted internal enquiry and also enquiry was conducted through one man expert committee under Retired High Court justice of Allahabad High court who found that the  said that caste atrocity had taken place. National Commission for Scheduled caste ,the  Constitutional body ordered for registration of First Information Report (FIR) against four professors. FIR was registered but in order to avoid arrest all,the accused professors approached Allahabad high court and got the FIR Stayed. 
      <br />
      <br />
      Subsequently  in Feb 2020,the Allahabad  High Court bench quashed FIR. The victim progessor has filed appeal in the Supreme Court and notices have been issued to all parties involved in this caste atrocitiy matter.
      <br />
      <br />
      IITs are top institutions and there are 23 Central Government IITs fully funded by the Govt. So they have to implement fully  affirmative action program of the Govt of India and recruit 22.50% SC-ST Community in all recruitment. As per govt data hardly 3%scst have been recruited in IITs and hundreds of posts meant for SC-ST are unfilled. In order to prevent entry of SC-ST Community in IITs, the bias minded uppercaste  supremacist faculty members try to harass and discourage SC-ST from entering the elite institutions.
      <br />
      <br />
      ACJP has is supporting Dr. Saderla  for securing Justice through legal and constitutional means.
      <br />
      <br />
      </CaseText>
      <CaseText>
        You can find the original article below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://swarajyamag.com/politics/cleared-of-plagiarism-charge-iit-kanpurs-dalit-faculty-opens-up-on-how-he-coped-with-humiliations-jibes-of-being-a-curse"
        size="small"
      />
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.telegraphindia.com/india/iit-go-ahead-for-demotion-over-caste-bias/cid/1705224"
        size="small"
      />
       <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.news18.com/news/india/a-dalit-scholars-curse-which-never-leaves-the-story-of-shunned-iit-k-academic-subrahmanyam-saderla-2085865.html"
        size="small"
      />
       <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.edexlive.com/campus/2019/apr/13/if-i-withdrew-discrimination-complaint-my-phd-would-have-been-cleared-iit-kanpur-scholar-subrahman-5778.html"
        size="small"
      />
       <div style={{ height: "24px" }} />
      <Microlink
        url="https://timesofindia.indiatimes.com/city/kanpur/iit-kanpur-professor-accused-of-plagiarism-can-retain-phd-board-shuts-case/articleshow/71148484.cms"
        size="small"
      />
       <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.timesnownews.com/mirror-now/crime/article/iit-kanpurs-4-professors-booked-under-scst-atrocities-act-for-harassing-dalit-co-worker/317112"
        size="small"
      />
       <div style={{ height: "24px" }} />
      <Microlink
        url="https://indianexpress.com/article/cities/lucknow/iit-kanpur-caste-harassment-faculty-forum-passes-resolution-to-divest-deputy-director-of-duties-5458299/"
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
