import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import Microlink from "@microlink/react";
import CaseOverview from "../components/Cases/CaseOverview";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dnaindia.com/sites/default/files/styles/full/public/2015/07/08/353891-death.jpg"
      }
    >
      Brutal murder of a school student  for falling in  love with his classmate, Ahmednagar, MH| Tag (News) 
    </CaseHeader>
    <CaseOverview
      title={`
      Brutal murder of a school student  for falling in  love with his classmate, Ahmednagar, MH| Tag (News 18) 
        `}
      metadata={"28 April 2014 • Ahmednagar Maharashtra • News 18 "}
    >
      <CaseText>
        <br />
        On 28th Apr 2014,one Scheduled Caste young man named Nitin Raju Aage aged 17 years, studying in XIIth Std, resident of Kharda Village in Jamkhed Taluka of Ahmednagar District in Maharashtra was pulled out of the class room, taken to nearby brick kiln, beaten, tortured, abused and killed and body hanged on a tree. 
        <br/><br/>
        The reason is that he fell in love with a young lady belonging to upper caste family. All the culprits were arrested ant tried in the sessions court in Ahmednagar Nagar. Due to shabby and improper investigation by police all accused were acquitted. Govg was supposed to go in to appeal in the High Court but they didn't appeal so far. So our organization is helping parents of Mr. Nitin Aage to appeal in the high court for justice.
        <br/><br/>
      </CaseText>
      <br />
      
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url=""
        size="small"
      />
      <div style={{ height: "24px" }} />
    </MediumContainer>
  </Layout>
);
export default Case;
