import React from "react";

const AboutSectionMain = () => {
  return (
    <div>
      <div>
        <img
          src="https://picsum.photos/1440/500"
          style={{ maxHeight: "500px", objectFit: "cover" }}
          width="100%"
        />
      </div>
    </div>
  );
};

export default AboutSectionMain;
