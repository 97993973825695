import React from "react";
import LogoImage from "./acjpright.jpg";
import {
  StyledHeader,
  Brand,
  BrandSmall,
  StyledMenu,
  Links,
  BrandContainer,
  StyledDropdown,
  StyledMenuDropdown,
  StyledMenuItem,
} from "./HeaderStyles";

import {
  StyledSecondaryButton,
  StyledPrimaryButton,
} from "./../styles/commonStyle";

const aboutMenu = (
  <StyledMenuDropdown>
    <StyledMenuItem key="0">
      <Links to="/about">ABOUT US</Links>
    </StyledMenuItem>
    <StyledMenuItem key="1">
      <Links to="/manifesto">MANIFESTO</Links>
    </StyledMenuItem>
    <StyledMenuItem key="1">
      <Links to="/acjpatun">ACJP AT UNITED NATIONS</Links>
    </StyledMenuItem>
    <StyledMenuItem key="3">
      <Links to="/community">COMMUNITY</Links>
    </StyledMenuItem>
  </StyledMenuDropdown>
);
const casesMenu = (
  <StyledMenuDropdown>
    <StyledMenuItem key="0">
      <Links to="/cases">News and Cases</Links>
    </StyledMenuItem>
    <StyledMenuItem key="1">
      <Links to="/cases">Case Studies</Links>
    </StyledMenuItem>
    <StyledMenuItem key="1">
      <Links to="/cases">Video Interviews</Links>
    </StyledMenuItem>
    <StyledMenuItem key="3">
      <Links to="/cases">Monthly Bulletin</Links>
    </StyledMenuItem>
    <StyledMenuItem key="3">
      <StyledPrimaryButton> REGISTER A CASE </StyledPrimaryButton>
    </StyledMenuItem>
  </StyledMenuDropdown>
);

class HeaderContainer extends React.Component {
  state = {
    current: "mail",
  };

  handleClick = e => {
    this.setState({
      current: e.key,
    });
  };

  render() {
    return (
      <StyledHeader>
        <BrandContainer>
          <img src={LogoImage} height="65px" width="65px" />

          <Brand to="/">
            <div>ACJP</div>
            <BrandSmall>Ambedkar Center for Justice and Peace <b>(Global Network)</b></BrandSmall>
          </Brand>
        </BrandContainer>
        <StyledMenu>
          <StyledDropdown
            overlay={aboutMenu}
            placement="bottomRight"
            trigger={["hover"]}
          >
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <Links to="/about">ABOUT</Links>
            </a>
          </StyledDropdown>
          <Links to="/reportsanddata">REPORTS AND DATA</Links>

          <StyledDropdown
            overlay={casesMenu}
            placement="bottomRight"
            trigger={["hover"]}
          >
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <Links to="/cases">CASES</Links>
            </a>
          </StyledDropdown>
          <StyledSecondaryButton style={{ marginLeft: "15px" }} size={"large"}>
            DONATE
          </StyledSecondaryButton>
        </StyledMenu>
      </StyledHeader>
    );
  }
}

export default HeaderContainer;
