import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Lynching and murders after rape at village, Khairlanji, Bhandara, MH| Tag (News) 
    </CaseHeader>
    <CaseOverview
      title={`
          Lynching and murders after rape at village, Khairlanji, Bhandara, MH| Tag (News) 

        `}
      metadata={"29 Sep 2006 • Bhandara MH • Compiled By ACJP "}
    >
      <CaseText>
        <br />
        <br />
        On 29th Sept. 2006, a mob of about 300 peoples (including women) with sharp weapons in their hands raided the house of Mr. Byaiyallal Bhotmange, aged 50 yrs. in Khairlanji village of Bhandara District, Maharashtra State. The mob led by Mr. Bhaskar Kawad and Prakash Kawad and his associates forced both sons to have sex with sister and mother and on refusal they were beaten mercilessly and hacked to death. Afterward the bodies were taken for immersion to a nearby irrigation canal in bullock cart with funfair and celebrations of victory.  This was going on for almost 3 hrs.  Most of the upper caste women instigated their men to commit atrocities.  The reasons given by some of the killers is that Bhotmange family were the witness in a criminal assault case filed by Mr. Siddharta Gajbhiye, cousin brother of Surekha Bhotmange, the deceased in the lynching case. 
        <br />
        <br />
        In this case there was a tremendous movement by scheduled caste Community throughout the country. ACJP had taken up the matter with NHRC ,NCSC State Government of Maharashtra and united Nations Human Rights Commission through CERD Committee in 2007State report discussion.
        <br />
        <br />
        Most of the culprits were tried and punished with life imprisonment though there was demand for death sentence in the most gruesome inhuman lynching of innocent Buddhist Community people.

        <br />
        <br />
      </CaseText>
      <CaseText>
        You can find the original articles below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://en.wikipedia.org/wiki/Khairlanji_massacre"
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
