import React from "react";
import {
  Container,
  FlexContainer,
  StyledSubHeader,
  StyledText,
} from "./../styles/commonStyle";
import { StyledMainContainer, StyledTextContainer } from "./AboutStyles";
const WhoWeAre = () => {
  return (
    <div style={{ background: "#eee" }}>
      <Container>
        <div style={{ padding: "5rem 0" }}>
          <FlexContainer center>
            <StyledMainContainer style={{ order: "2" }}>
              <img
                src="https://picsum.photos/600/500"
                style={{ objectFit: "cover" }}
                width="100%"
              />
            </StyledMainContainer>
            <StyledTextContainer style={{ order: "1" }}>
              <StyledSubHeader>WHO WE ARE</StyledSubHeader>
              <StyledText>
              We are the peaceful social justice activists around the globe who are constantly working to educate the global civil society on the ill's of caste based discrimination affecting 300 millions Scheduled Castes and Scheduled Tribes and works for restoring their fundamental and human rights through Indian constitution and UN fundamental rights instruments to which countries are signatories.
                <br />
                <br />
                ACJP is an International organization founded by Mr. Yogesh Varhade in 1991 for the Protection of Human rights of more than 300 Millions SC's and ST's in India. It is a non-governmental organization which advocates for human rights through identification of their violation, collecting incident data, its analysis and publication, promotion of public awareness while conducting institutional advocacy, and lobbying to halt these violations.
              </StyledText>
            </StyledTextContainer>
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default WhoWeAre;
