import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";
import case2Img from "../components/images/case2_529.jpeg";

const Case = () => (
  <Layout>
    <CaseHeader
      image={case2Img}

    >
      Mother shot dead while preventing abduction of the daughter in Ahmednagar, MH
    </CaseHeader>
    <CaseOverview
      title={`
        Mother shot dead while preventing abduction of the daughter in Ahmednagar, MH
        `}
      metadata={"17 February 2020 • Ahmednagar MH • ACJP  "}
    >
      <CaseText>
      Date:17/02/2020
      <br />
      <br />
      Place:Wadzire village, Taluka Parner, Distt-Ahmednagar, Maharashtra State.
      <br />
      <br />
      Victims:Mrs Savita Sunil Gaikwad age-37yrs
      <br />
      <br />
      Accused:Mr Rahul Sable and 4 associates.
      <br />
      <br />
      Brief of Incident:
      <br />
      <br />
      Mrs Savita Gaikwad mother of two young college girls was shot dead by a gang of five while preventing abduction of her daughter on 17th Feb 2020 at Wadzire village,Taluka-Parner, Distt-Ahmednagar, Maharashtra State in India.
      <br />
      <br />
      The gangleader first kidnapped Ms Ashmita Gaikwad two years back while on her way to her home and forcefully married and later brought back .This happened twice and FIR for abduction and rape.The accused Mr Sable ,a goon,was arrested  and given bail which should have been opposed but police didn't oppose it.
      <br />
      <br />
      Embolden by this ,he tried again to kidnap Ms Asmita Gaikwad and when mother Savita Gaikwad tried to save the daughter Asmita, Rahul Sable shot dead mother Mrs Savita Gaikwad by a revolver point blank  on dated 17/2/2020 evening.
      <br />
      <br />
      In this case ,out of five ,four accused have been arrested and one accused is still atlarge and police is not able to nab him. Police said investigation is on.
      <br />
      <br />
      Both sisters Ms. Asmita and Shreya Gaikwad are in shock and receiving life threats  by goonda  elements of  Parner ,Ahmednagar who are associates of Rahul Sable the murderer.
      <br />
      <br />
      The daughters have demanded police protection as they are orphan. Their father left them in their  childhood.
      <br />
      <br />
      This is one of the very serious case where police inaction is seen from the complaint by the relatives of Ms Asmita Sunil Gaikwad who  registered complaint against Rahul Sable for kidnapping of Ms Asmita Gaikwad but instead of registering FIR, a simple NC was registered in Second kidnapping by Parner police Police Inspector Mr Bajirao Powar which is the matter of investigation and action under section 4 of POA Act 1989.
      <br />
      <br />
      </CaseText>


      <CaseText>
        You can find the original article below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <Microlink
        url=""
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
