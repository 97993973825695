import React from "react";
import { Container, StyledHeading, StyledText } from "../styles/commonStyle";
import murderCasesImg from "../images/atrocity_murder_cases.png";
const ReportsContainer2 = () => {
  return (
    <div>
      <Container style={{ padding: "5rem" }}>
        <img
          src={murderCasesImg}
          width="100%"
          style={{ padding: "0rem 0 0rem" }}
        />
        <b>Line chart showing trend of total murder cases against SC/ST between year 2009 to 2019 with clear separation of UPA(Congress lead) and NDA(BJP lead) government periods</b>
        <StyledHeading style={{ padding: "2rem 0 2rem" }}>
          Murders | Report from 2009 - 2019
        </StyledHeading>
        <StyledText>
        Murder and lynching if SC-ST men and women have been increasing every year due the dominant caste mentality that they can get away with murders because their castd men are in police and judiciary and powerful politicians brlongs to their community.Low rate of conviction in murder cases is also responsible for psychic of perpetrators of crimes that they will easily get away with use on money and muscle.
        This is also the failure of Justice system in India.
        Awareness about rights of SC-ST Community and provisions of law and atrocitiy act is also responsible for registration of murder cases by families of the victims.
        </StyledText>
      </Container>
    </div>
  );
};

export default ReportsContainer2;
