import React from "react";
import { Container, StyledHeading, StyledText } from "../styles/commonStyle";
import totalCasesImg from "../images/atrocity_total_cases.png";
const ReportsContainer2 = () => {
  return (
    <div>
      <Container style={{ padding: "5rem" }}>
        <img
          src={totalCasesImg}
          width="100%"
          style={{ padding: "0rem 0 0rem" }}
        />
        <b>Line chart showing trend of total atrocity cases against SC/ST between year 2009 to 2019 with clear separation of UPA(Congress lead) and NDA(BJP lead) government periods</b>
        
        <StyledHeading style={{ padding: "2rem 0 2rem" }}>
          Total Crimes | Report from 2009 - 2019
        </StyledHeading>
        <StyledText>
        This chart shows total registered cases of atrocities against SC-ST Community in India since 2009-2019 . It is seen that annual registration of atrocities cases has gradually increased from 2013-14  onward .The reasons of rise in total cases of atrocities are feeling among the high caste communities that police and judiciary could be managed and the perpetrators can get away with money muscle and power of their own caste sitting in police stations and judiciary.
        Registration in numbers are also due to increased awareness among SC-ST victim community due to rise in Education, awareness aboug rights through social media like whatsapp and Facebook and U-Tube.
        Large number of acquittal in pending cases in coursts is also responsible for thd mentality of criminals that though cases are registered but at the end they will get acquittal in the crimes against SC-ST community.
        This is also the indication of the failure of justice system in India.
        </StyledText>
      </Container>
    </div>
  );
};

export default ReportsContainer2;
