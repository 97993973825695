import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Caste abuse, humiliation and molestation of a woman, Dombivali, Thane, MH| Tag (News)  
    </CaseHeader>
    <CaseOverview
      title={`
        Caste abuse, humiliation and molestation of a woman, Dombivali, Thane, MH| Tag (News)  

        `}
      metadata={"3 March 2006 • Thane MH • Compiled By ACJP "}
    >
      <CaseText>
        <br />
        <br />
        Mrs. Asha Chimodia, a school teacher of Hindi School, Ram Nagar, Dombivali belonging to Kori Scheduled Caste community of Utter Pradesh State. She was caste abused humiliated and threatened on 23/3/2006 by the head master of the school Mr. Chandrajit Singh. He used unparliamentarily language in presence of teachers and school children and said that women of her caste are kept in the farms of upper caste in Uttar Pradesh and she can not do anything against him as he knows the law. Other teachers, Mr. Mahendra Shukla and Mrs. Sanjivani Singh also abused Mrs. Chimodia. She was harassed, abused and humiliated and given false charge sheets several time so that she could be dismissed from service.
        FIR was registered and accused were arrested.
        <br />
        <br />
        Place: Koliwada, Thane (E)
        <br />
        <br />
        Name of victims:
        Ms. Asha Chimodia                                       
        <br />
        <br />          
        Nature of Complaint: Criminal under SC/ST Prevention of Atrocity Act, 1989 and Protection of Civil Rights, 1955, Act
        <br />
        <br />
        FIR NO: II 31/ 06 Dombivali police, Station, District -Thane   
        <br />
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url=""
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
