import styled from "styled-components";
import { Layout } from "antd";
const { Header } = Layout;
import { Link } from "gatsby";
import theme from "./../styles/theme";
import { Menu, Dropdown } from "antd";

export const Brand = styled(Link)`
  font-size: 34px;
  padding: 0rem 0;
  text-decoration: none;
  font-weight: 600;
  color: ${theme.colors.primary};
  line-height: 1;
  margin-left: 12px;
`;

export const BrandSmall = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;
export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 5;
  width: 100%;
  width: 100%;
  display: flex;
  background: ${theme.colors.white};
  height: 80px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
`;

export const Links = styled(Link)`
  font-size: 1rem;
  margin: 0.25rem 1.5rem;
  color: ${theme.colors.primary};
  font-weight: 500;
  text-decoration: none;
  position: relative;
`;

export const StyledMenuDropdown = styled(Menu)`
  border-radius: 5px;
  min-width: 150px;
  border: 1px solid ${theme.colors.primary};
`;
export const StyledMenuItem = styled(Menu.Item)`
  color: ${theme.colors.primary};
  padding: 10px 16px;

  ${Links} {
    font-size: 14px;
    color: ${theme.colors.primary};
  }
  &:hover {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    ${Links} {
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  }
`;
export const StyledDropdown = styled(Dropdown)``;
