import React from "react";
import Layout from "../components/Layout/Layout";
import MainImage from "../components/ReportsAndData/MainImage";
import ReportsContainer1 from "../components/ReportsAndData/ReportsContainer1";
import ReportsContainer2 from "../components/ReportsAndData/ReportsContainer2";
import ReportsContainer3 from "../components/ReportsAndData/ReportsContainer3";
import ReportsContainer4 from "../components/ReportsAndData/ReportsContainer4";
import BarChartContainer from "../components/ReportsAndData/BarChartContainer";
import PieChartContainer from "../components/ReportsAndData/PieChartContainer";

const ReportsPage = () => (
  <Layout>
    {/*<ManifestoImage />*/}
    <MainImage />
    <ReportsContainer1 />
    <ReportsContainer2 />
    <ReportsContainer3 />
    <ReportsContainer4 />
    {/* <BarChartContainer />
    <PieChartContainer /> */}
  </Layout>
);
export default ReportsPage;
