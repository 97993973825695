import React from "react";
import { Container, StyledHeading, StyledText } from "../styles/commonStyle";
const VisionNew = () => {
  return (
    <div>
      <Container style={{ padding: "0rem" }}>
        <StyledHeading style={{ padding: "2rem 0 2rem" }}>
          Our Vision
        </StyledHeading>
        <StyledText>
        Our vision is to create Casteless society where all human beings are equal in dignity and rights in South Asia and around the world.
              </StyledText>
      </Container>
    </div>
  );
};

export default VisionNew;
