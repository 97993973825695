import React from "react";
import { Container } from "./../styles/commonStyle";
import { CaseHeaderContainer, CaseMainHeading } from "./CasesStyles";

const CaseHeader = ({ children, image }) => {
  return (
    <CaseHeaderContainer image={image}>
      <Container>
        <CaseMainHeading> {children}</CaseMainHeading>
      </Container>
    </CaseHeaderContainer>
  );
};

export default CaseHeader;
