import React from "react";
import {
  Container,
  FlexContainer,
  StyledSubHeader,
  StyledText,
} from "./../styles/commonStyle";
import { StyledMainContainer, StyledTextContainer } from "./AboutStyles";
const WordFromFounder = () => {
  return (
    <div>
      <Container>
        <div style={{ padding: "5rem 0" }}>
          <FlexContainer center>
            <StyledMainContainer>
              <img
                src="https://i.ytimg.com/vi/7fgSD1iUmgU/hqdefault.jpg"
                style={{ objectFit: "cover" }}
                width="100%"
              />
            </StyledMainContainer>
            <StyledTextContainer>
              <StyledSubHeader>WORD FROM THE FOUNDER</StyledSubHeader>
              <StyledText>
              In Indian caste system the lowest of the low the untouchables and tribal , nearly 300 million, have to clean the toilets, clean streets, carry dead animals-the most unhygienic jobs and thus created a biggest slavery in the world.
<br/><br/>
The reports of ilo, UNICEF India, world bank, anti-slavery international -all bring  us to the reality that:
India has  about 17% of global population but its share of child labor is about 50% of the world, bonded labor is 50% of the world, trafficking of girls and women is 50% of the world and there are no serious efforts to 
solve these challenges except making laws without the will of the government to implement them. Most of these people including children, women and men are from so called untouchables and tribal the most marginalized.
           </StyledText>
            </StyledTextContainer>
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default WordFromFounder;
