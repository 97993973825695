import React from "react";
import { Container } from "./../styles/commonStyle";
import {
  CaseOverviewContainer,
  CaseOverviewHeading,
  MediumContainer,
} from "./CasesStyles";

const CaseHeader = ({ title, metadata, children }) => {
  return (
    <CaseOverviewContainer>
      <Container>
        <MediumContainer>
          <CaseOverviewHeading>{title}</CaseOverviewHeading>
          <hr />
          <p
            style={{
              fontSize: "16px",
              margin: "0",
              color: "#808080",
              padding: "1rem 0",
              lineHeight: "1",
            }}
          >
            {metadata}
          </p>
          <hr />
          {children}
        </MediumContainer>
      </Container>
    </CaseOverviewContainer>
  );
};
export default CaseHeader;
