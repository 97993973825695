import React from "react";
import { Container } from "../styles/commonStyle";
import HighlightedContainer from "./HighlightedContainer";
import case7Img from "../images/case7_534.jpeg";
const MainCase2 = () => (
  <div style={{ margin: "5rem 0" }}>
    <Container>
      <HighlightedContainer
        reverse
        title="Lynching of a Buddhist(Scheduled Caste) boy due to playing Dr. Ambedkar’s rington, Ahmednagar, Maharashtra State"
        color="#d7b69a"
        source={case7Img}
      >
        Mr. Sagar Shejwal a student of nursing college was hit with a beer bottle and later lynched  by four high caste youths in beer bar on 16May 2015 afternoon at Shirdi, Ahmednagar district, Maharashtra state for refusing to stop Dr. Babasaheb Ambedkar’s ringtone in his mobile. He was at Shirdi town to attend to a wedding and was sitting in the beer bar listening Dr. Babasaheb Ambedkar’s song on his mobile. The accused asked him to stop the ringtone where the victim had argument with the upper caste boys.
      </HighlightedContainer>
    </Container>
  </div>
);

export default MainCase2;
