import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Denied Access to Crematorium, TN Dalits Wait with Corpse in Rain, Peraiyur, TN| Tag (News) 
    </CaseHeader>
    <CaseOverview
      title={`
      Denied Access to Crematorium, TN Dalits Wait with Corpse in Rain, Peraiyur, TN| Tag (News)  

        `}
      metadata={"2 September 2019 • Peraiyur TN • Compiled By ACJP "}
    >
      <CaseText>
        <br />
        <br />
        Caste Hindus refused to allow the Dalit Community from Peraiyur to use their burial ground despite incessant rains.

        Only last week, a video shot on 17 August in Vellore District that showed a man's dead body being lowered from a bridge created massive debate on caste discrimination in Tamil Nadu and exposed how this discrimination extended even to the dead.
        As the video went viral and media attention grew, the district administration was forced to intervene and provide better facilities to Dalits. But in a tragic coincidence, even as this video was being shot in Vellore, over 500 kilometres away in Madurai, a similar case of discrimination was unravelling.
        On the same day, members of the Adi Dravida community (a Dalit community) from Peraiyur village in Subbalapuram were carrying the corpse of 50-year-old Shanmugavel to their traditional burial ground, when it began to rain incessantly.
        While there are only 50 Dalit families in the village, there are over 150 caste Hindu families belonging to the Reddiyar community. According to Chellakannu, the two communities have separate burial grounds – the Dalits a bare patch of land and the caste Hindus a protected ground with sheds to burn bodies in. While the two grounds are adjacent, a tall compound wall surrounds the property of the caste Hindus.
        A video from the funeral shows relatives of Shanmugavel lamenting over how they had to burn the body amidst the rain. They also complain about the lack of infrastructure on their burial ground.
        "It is raining heavily, we are not able to do anything. Please help our community," says one man, standing near the corpse and pleading with folded hands into the camera.
        Another man, who claims to be the brother of the deceased, angrily proclaims, "They are discriminating against us. They have all the facilities. Look at us, standing in the rain. He is my own brother. Look at their burial ground and look at ours. What is the government doing?"
        The Dalits, with no other option in hand, then stood by the corpse covering it with a straw mat to keep the fire burning.
        "The fire was put off by the rain when the body was only half burnt and they had to pour petrol on it and burn it again," says Chellakannu.
        Later, the two communities went to the police station over the issue.
        <br />
        <br />
      </CaseText>
      <CaseText>
        You can find the original articles below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url=""
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
