import styled from "styled-components";
import theme from "./../styles/theme";

export const StyledMainContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;

export const StyledTextContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;
export const QuoteContainer = styled.div`
  padding: 2rem;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  position: relative;
  margin-bottom: 30px;
  left: 30px;
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0px;
    width: 0;
    border-top: 20px solid ${theme.colors.primary};
    border-left: 0px solid transparent;
    border-right: 20px solid transparent;
  }
`;

export const Quote = styled.div`
  padding: 2rem;
`;
