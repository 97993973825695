import React from "react";
import {
  Container,
  StyledSubHeader,
  StyledText,
  StyledHeading,
} from "./../styles/commonStyle";
import ManifestoImage from "./../images/manifestoimage@2x.png";
const ManifestoBody = () => {
  return (
    <div>
      <Container style={{ paddingBottom: "10rem" }}>
        <img src={ManifestoImage} width="100%" style={{ padding: "5rem" }} />
        <br />
        <StyledHeading style={{ padding: "0 5rem" }}>
          Overview of our Work Manifesto
        </StyledHeading>
        <StyledSubHeader style={{ padding: "2rem 5rem" }}>
          Manifesto Points
        </StyledSubHeader>
        <StyledText style={{ padding: "0 5rem 1rem" }}>
          Neither the markets nor aid alone can solve the problems of poverty.
          More than two billion people around the world lack access to basic
          goods and services—from clean water and electricity to an education
          and the freedom to participate in the economy. We’re here to change
          that. Our vision is a world based on dignity, where every human being
          has the same opportunity. Rather than giving philanthropy away, we
          invest it in companies and change makers.
        </StyledText>
        <StyledText style={{ padding: "0 5rem 1rem" }}>
          Neither the markets nor aid alone can solve the problems of poverty.
          More than two billion people around the world lack access to basic
          goods and services—from clean water and electricity to an education
          and the freedom to participate in the economy. We’re here to change
          that. Our vision is a world based on dignity, where every human being
          has the same opportunity. Rather than giving philanthropy away, we
          invest it in companies and change makers.
        </StyledText>
        <StyledText style={{ padding: "0 5rem 1rem" }}>
          Neither the markets nor aid alone can solve the problems of poverty.
          More than two billion people around the world lack access to basic
          goods and services—from clean water and electricity to an education
          and the freedom to participate in the economy. We’re here to change
          that. Our vision is a world based on dignity, where every human being
          has the same opportunity. Rather than giving philanthropy away, we
          invest it in companies and change makers.
        </StyledText>
        <StyledText style={{ padding: "0 5rem 1rem" }}>
          Neither the markets nor aid alone can solve the problems of poverty.
          More than two billion people around the world lack access to basic
          goods and services—from clean water and electricity to an education
          and the freedom to participate in the economy. We’re here to change
          that. Our vision is a world based on dignity, where every human being
          has the same opportunity. Rather than giving philanthropy away, we
          invest it in companies and change makers.Neither the markets nor aid
          alone can solve the problems of poverty. More than two billion people
          around the world lack access to basic goods and services—from clean
          water and electricity to an education and the freedom to participate
          in the economy. We’re here to change that. Our vision is a world based
          on dignity, where every human being has the same opportunity. Rather
          than giving philanthropy away, we invest it in companies and change
          makers. Neither the markets nor aid alone can solve the problems of
          poverty. More than two billion people around the world lack access to
          basic goods and services—from clean water and electricity to an
          education and the freedom to participate in the economy. We’re here to
          change that. Our vision is a world based on dignity, where every human
          being has the same opportunity. Rather than giving philanthropy away,
          we invest it in companies and change makers.
        </StyledText>
        <StyledText style={{ padding: "0 5rem 1rem" }}>
          Neither the markets nor aid alone can solve the problems of poverty.
          More than two billion people around the world lack access to basic
          goods and services—from clean water and electricity to an education
          and the freedom to participate in the economy. We’re here to change
          that. Our vision is a world based on dignity, where every human being
          has the same opportunity. Rather than giving philanthropy away, we
          invest it in companies and change makers.Neither the markets nor aid
          alone can solve the problems of poverty. More than two billion people
          around the world lack access to basic goods and services—from clean
          water and electricity to an education and the freedom to participate
          in the economy. We’re here to change that. Our vision is a world based
          on dignity, where every human being has the same opportunity. Rather
          than giving philanthropy away, we invest it in companies and change
          makers. Neither the markets nor aid alone can solve the problems of
          poverty. More than two billion people around the world lack access to
          basic goods and services—from clean water and electricity to an
          education and the freedom to participate in the economy. We’re here to
          change that. Our vision is a world based on dignity, where every human
          being has the same opportunity. Rather than giving philanthropy away,
          we invest it in companies and change makers.
        </StyledText>
        <StyledText style={{ padding: "0 5rem 1rem" }}>
          Neither the markets nor aid alone can solve the problems of poverty.
          More than two billion people around the world lack access to basic
          goods and services—from clean water and electricity to an education
          and the freedom to participate in the economy. We’re here to change
          that. Our vision is a world based on dignity, where every human being
          has the same opportunity. Rather than giving philanthropy away, we
          invest it in companies and change makers.Neither the markets nor aid
          alone can solve the problems of poverty. More than two billion people
          around the world lack access to basic goods and services—from clean
          water and electricity to an education and the freedom to participate
          in the economy. We’re here to change that. Our vision is a world based
          on dignity, where every human being has the same opportunity. Rather
          than giving philanthropy away, we invest it in companies and change
          makers. Neither the markets nor aid alone can solve the problems of
          poverty. More than two billion people around the world lack access to
          basic goods and services—from clean water and electricity to an
          education and the freedom to participate in the economy. We’re here to
          change that. Our vision is a world based on dignity, where every human
          being has the same opportunity. Rather than giving philanthropy away,
          we invest it in companies and change makers.
        </StyledText>
      </Container>
    </div>
  );
};

export default ManifestoBody;
