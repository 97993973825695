import React from "react";
import { Container, StyledText } from "../styles/commonStyle";
const WordFromFounderMore = () => {
  return (
    <div>
      <Container style={{ padding: "0rem" }}>
        <StyledText>
        1-5 year old children are mal -nourished and their death rate is highest in the world says UNICEF India report..
As far as the atrocities are concerned-the rapes of marginalized girls and women belonging to scheduled caste and scheduled tribes (Dalits and tribal) have increased by 50% plus in the last 6 years since bjp the Hindu fundamentalist party, ruled  the country.
Recent killing of a girl 19 years old by 4 caste biased high caste Hindus, by raping her and breaking her spinal column, cutting the tongue so that she does not speak about the criminals, is just one of the terrorizing events and then police help culprits due to prejudice and corruption .Finally she died and her body was brought in at 1 am midnight to her village and last rights were done by police  without allowing her mother to participate in it.
<br/><br/>
          NCRB of India notes about 3500 plus cold blood killings per year and 5500 rapes of girls and women per year and ex home minister Chidambaram says that most of the atrocities are not recorded which means only 10% are recorded and 90% are not and in that also conviction rate is less than 6%.
So to deal with this massive atrocity problem and get the justice to the victims., though atrocity act has been well written but poorly implemented, Ambedkar center for justice and peace was born in north America and working since 1991 with global chapters, and succeeding one case at a time. Our website has full details on the atrocities for study.
We are also educating the global civil society for the last 30 years through un as Babasaheb declared in 1946 and again in 1953 that this problem of caste based discrimination cannot solved in India and need to be taken to un like apartheid of south Africa..
It is worthwhile to remember that in 1996 committee on elimination of racial discrimination (CERD) at un recommended to India" India should start a continuous human rights education to Indian population in line with Indian constitution and un human rights instruments including convention on elimination of racial discrimination . This should be aimed at eradicating  high caste and low caste mentality"(article 31,cerd /c/304/add.13-17/9/96)
India did not do anything.
Finally what dr. Ambedkar said to the global civil society needs to be remembered.
It is the duty of the global civil society to break their shackles and set them free as it owes  to all enslaved people around the world.!" dr. Ambedkar.
              <br/><br/>
              </StyledText>
      </Container>
    </div>
  );
};

export default WordFromFounderMore;
