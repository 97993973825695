import React from "react";
import {
  Container,
  StyledText,
  StyledSubHeader,
} from "./../styles/commonStyle";

import { DeclarationBlob } from "./ACJPatUNStyles";

const UNDeclaration = () => {
  return (
    <Container>
      <DeclarationBlob>
        <StyledSubHeader style={{ color: "#fff" }}>
          United Nations directed India to start Human Rights Education
        </StyledSubHeader>
        <StyledText style={{ padding: "0.5rem 0", color: "#fff" }}>
          It is important to note that CERD (Committee on the Elimination of
          Racial Discrimination) gave a very valuable recommendation in its 1996
          Treaty Body CERD Concluding Observations (CERD/C/304/Add.13 dated
          17/09/96 article 31) stating
          <strong style={{ fontWeight: 500 }}>
            “The committee recommends a continuing campaign to educate Indian
            population on Human Rights, inline with the constitution of India &
            universal Human Rights Instruments, including the International
            Convention of All Forms of Racial Discrimination. This should be
            aimed at eliminating the institutionalized thinking of the high
            caste and low caste mentality”.
          </strong>
          <br />
          <br />
          India has not implemented it yet.
        </StyledText>
      </DeclarationBlob>
    </Container>
  );
};

export default UNDeclaration;
