import React from "react";
import { Container, StyledHeading, StyledText } from "../styles/commonStyle";
const ReportsContainer1 = () => {
  return (
    <div>
      <Container style={{ padding: "5rem" }}>
        <StyledHeading>Atrocity Data and Interpretations</StyledHeading>
        <StyledText>
        <br/>
        Caste based human rights violations in India is a serious problem of 250 million untouchables in India. This is a unique from of Hindu Society who considers it's outcaste as lowest of low in purity and the top class Brahmins, Kshatriyas, Vaishyas and most of the backward Shudra consider it is their duty to oppress and suppress untouchable communities and thus they don't feel any shame rather they feel it their pride to rape gang rape murder loot burn assault humiliate insult torture the untouchables who are called as Scheduled caste ,the name given by Indian Constitution. On the ground that tribal from forest inaccessible areas poor and illiterates, Hindu community behave in similar ways to tribal and carry out a with impunity. Though there is the Constitutional authority of National Commission for Scheduled Caste and Scheduled Tribes, the agency is good for nothing. Their members and chairman are political people appointed on the basis of their loyalty to the particular ruling party and they hardly perform their duty under pressure and obligation of their respective party. In millions of cases of violations of human rights in slums ,rural areas and in offices, hardly 1 or 2 percent people get any justice. Most Members don’t have idea as to how to use legal powered vested with them as they are politicians and no knowledge of legal system.
        <br/> <br/>
        In our study of human rights cases since 2001 to 2019, we have noticed that cases of atrocities or human rights violations of untouchables and tribal are rising  steadily despite Government claim that they are doing their best to prevent atrocities. As per the law , Ministry of Social Justice is preparing annual report of human rights violations and cases of discrimination is state and central services and placing the report on the table of parliament of India through the President of India. It has become a formality. As per the spirit of the Constitution, the government of India must debate and discuss the report every year and  take stock of various legislations made for the promotion and protection of SC-ST Community or untouchable and tribal community but everything is on paper. During 2019, more than 55000 cases of serious Human Rights Violations of SC-ST had taken place which includes 7000 plus cases of rape murder and molestation. About two lakh cases of atrocities or human rights Violations are pending for trial for last 5to 15years and conviction rate is extremely low. Actual cases of caste atrocities and human rights violations are 10 times more or even if it may 20 times more as registration of atrocities in police stations is extremely difficult task. So most cases goes unreported due to fear of Police torture or fear of attack by Hindu castiest people or many a time ignorance about the law and their civil rights.
        <br/><br/>
        We urge global civil society to take note of serious state of affairs in enjoyment of civil rights by 350 million SC-ST Community in India and Indian Govt must life the ban of visit of special rapporteurs of United Nations various committees created for implementation of human rights instruments. Global Civil Society must ask questions to the state party India and forced them to implement provisions of fundamental rights in their constitution in a time bound manner and they must come out with a time bound  plan to end caste based human rights Violations and show that India is truly a biggest and best democracy on this planet. 
        </StyledText>
      </Container>
    </div>
  );
};

export default ReportsContainer1;
