import styled from "styled-components";

export const StyledMainContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;

export const StyledTextContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;