import React from "react";
import Layout from "./../components/Layout/Layout";
import MainImage from "./../components/Community/MainImage";
import Overview from "./../components/Community/Overview";
import Testimonial from "../components/Community/Testimonial";
import CommunitySpotlight from "../components/Community/CommunitySpotlight";

const AboutPage = () => (
  <Layout>
    <MainImage />
    <Overview />
    <Testimonial />
    <CommunitySpotlight />
  </Layout>
);
export default AboutPage;
