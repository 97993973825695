import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://d2c7ipcroan06u.cloudfront.net/wp-content/uploads/2019/09/Home-of-Avinash-Valmiki.jpg"
      }
    >
      Children Beaten to Death for Open Defecation in Shivpuri, MP| Tag (News)
    </CaseHeader>
    <CaseOverview
      title={`
        Children Beaten to Death for Open Defecation in Shivpuri, MP| Tag (News) 

        `}
      metadata={"9 October 2019 • Shivpuri MP • Compiled By ACJP "}
    >
      <CaseText>
        <br />
        <br />
        Siblings, Roshni Valmiki (12) and her younger brother Avinash (10), were beaten to death by two upper caste men in Bhavkhedi  district Shivpuri ,Madhya Pradesh for openly defecating on the road opposite the Panchayat Building at about 6.30am on 25th Sept 2019.
        <br />
        <br />
        Their father, Bablu Valmiki, a daily wage laborer, immediately rushed to the spot and took the injured children to the district hospital where they were declared dead on arrival. Both the accused were arrested under IPC and SC-ST Prevention of Atrocities Act.
        <br />
        <br />
        The Valmiki’s residence was the only one without an attached toilet in the whole village. Bablu had made several requests to the Panchayat to provide them with the facility under the Govt scheme but they did not oblige. After the incident, Member of Parliament Jyotindriya Scindia visited the Valmiki family and made promises to them of a government job, along with 20 acres of agriculture land, which are yet to be fulfilled.
        <br />
        <br />
        This incident shows that the PM’s Swaccha Bharat Scheme is being selectively implemented by village officials, depriving the SC-ST community of its benefit. The state of Madhya Pradesh ranks first in the atrocities against SC-ST community with several thousand cases, as old as 5-15 years, pending in to be tried in court.
        <br />
        <br />
      </CaseText>
      <CaseText>
        You can find the original articles below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url=""
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
