import React from "react";
import Microlink from "@microlink/react";
import { FlexContainer, Container, StyledHeading } from "../styles/commonStyle";

const Article = [
  {
    id: 1,
    link:
      "https://www.thehindu.com/news/national/other-states/iit-kanpur-vendetta-against-dalit-teacher-flayed/article26738022.ece",
  },
  {
    id: 2,
    link:
      "https://indianexpress.com/article/cities/mumbai/khairlanji-dalit-family-massacre-waiting-for-justice-bhaiyyalal-bhotmange-dies-of-heart-attack-4484503/",
  },
  {
    id: 3,
    link:
      "https://www.thehindu.com/news/national/other-states/two-dalit-children-beaten-to-death-2-held-in-madhya-pradesh/article29506925.ece",
  },
  {
    id: 4,
    link:
      "https://www.thenewsminute.com/article/denied-access-crematorium-madurai-dalits-forced-wait-corpse-rain-108246",
  },
  {
    id: 5,
    link:
      "https://nextshark.com/india-caste-love-story/",
  },
  {
    id: 6,
    link:
      "https://www.tribuneindia.com/news/archive/amritsar/farm-labourer-beaten-to-death-818193",
  },
];

const MoreLinks = () => {
  return (
    <>
      <Container style={{ margin: "5rem auto 2rem" }}>
        <StyledHeading> More Cases</StyledHeading>
      </Container>
      <Container style={{ margin: "0rem auto 5rem" }}>
        <FlexContainer style={{ flexWrap: "wrap" }} center>
          {Article.map(item => (
            <div key={item.id} style={{ padding: "2rem", width: "33%" }}>
              <Microlink url={item.link} size="large" />
            </div>
          ))}
        </FlexContainer>
      </Container>
    </>
  );
};

export default MoreLinks;
