import React from "react";
import { Layout } from "antd";
import HeaderContainer from "./../Header/Header";
import FooterContainer from "./../Footer/Footer";
import "antd/dist/antd.css";
import "./styles.css";
import { StyledContentContainer, StyledContent } from "./LayoutStyle";

// eslint-disable-next-line react/prop-types
const LayoutContainer = ({ children }) => (
  <Layout>
    <HeaderContainer />
    <StyledContentContainer>
      <StyledContent className="site-layout">{children}</StyledContent>
      <FooterContainer />
    </StyledContentContainer>
  </Layout>
);

export default LayoutContainer;
