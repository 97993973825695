import React from "react";
import ProtestImage from "./../images/protest.jpeg";

const MainImage = () => {
  return (
    <div>
      <div>
        <img
          src={ProtestImage}
          style={{ maxHeight: "500px", objectFit: "cover" }}
          width="100%"
        />
      </div>
    </div>
  );
};

export default MainImage;
