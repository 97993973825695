import React from "react";
import {
  FlexTextHightlight,
  FlexImageHighlight,
  FlexTexSmall,
  FlexTextHeader,
  FlexImageTextHighlight,
} from "./CasesStyles";
import { Container } from "./../styles/commonStyle";
const HighlightedContainer = ({
  title,
  children,
  reverse = false,
  color = "#f0f0f0",
  source = "https://picsum.photos/300/200",
}) => {
  return (
    <Container>
      <FlexImageTextHighlight color={color} reverse={reverse}>
        <FlexTextHightlight>
          <FlexTextHeader>{title}</FlexTextHeader>
          <FlexTexSmall>{children}</FlexTexSmall>
        </FlexTextHightlight>
        <FlexImageHighlight src={source} />
      </FlexImageTextHighlight>
    </Container>
  );
};

export default HighlightedContainer;
