import React from "react";
import Layout from "./../components/Layout/Layout";
import ACJPMainImage from "../components/ACJPatUN/MainImage";
import UNDeclaration from "../components/ACJPatUN/UNDeclaration";
import Overview from "../components/ACJPatUN/Overview";
import Archievements from "../components/ACJPatUN/Achievements";
import ACJPTimeline from "../components/ACJPatUN/ACJPTimeline";

const AboutPage = () => (
  <Layout>
    <ACJPMainImage />
    <UNDeclaration />
    <Overview />
    <Archievements />
    <ACJPTimeline />
  </Layout>
);
export default AboutPage;
