import React from "react";
import {
  Container,
  FlexContainer,
  StyledHeading,
  StyledSubHeader,
  StyledText,
} from "../styles/commonStyle";
import { StyledMainContainer, StyledTextContainer } from "./CommunityStyles";
const Overview = () => {
  return (
    <div>
      <Container>
        <div style={{ padding: "5rem 0" }}>
          <FlexContainer top>
            <StyledMainContainer>
              <StyledHeading>WHAT COMMUNITY MEANS</StyledHeading>
            </StyledMainContainer>
            <StyledTextContainer>
              <StyledSubHeader>Overview</StyledSubHeader>
              <StyledText>
                Neither the markets nor aid alone can solve the problems of
                poverty. More than two billion people around the world lack
                access to basic goods and services—from clean water and
                electricity to an education and the freedom to participate in
                the economy. We’re here to change that. Our vision is a world
                based on dignity, where every human being has the same
                opportunity. Rather than giving philanthropy away, we invest it
                in companies and change makers.
              </StyledText>
            </StyledTextContainer>
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default Overview;
