import React from "react";
import { Container, StyledHeading, StyledText } from "../styles/commonStyle";
const WeBelieve = () => {
  return (
    <div>
      <Container style={{ padding: "5rem 0" }}>
      <StyledHeading style={{ padding: "0rem 0 0rem" }}>
      We believe in principles that all human beings are born free, equal in dignity and rights and are destined to reach highest human potential as they decide with their own free will.
        </StyledHeading>
      </Container>
    </div>
  );
};

export default WeBelieve;
