import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import Microlink from "@microlink/react";
import CaseOverview from "../components/Cases/CaseOverview";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Lynching of a Buddhist(Scheduled Caste) boy due to playing Dr. Ambedkar’s rington, Ahmednagar, Maharashtra State  
    </CaseHeader>
    <CaseOverview
      title={`
      Lynching of a Buddhist(Scheduled Caste) boy due to playing Dr. Ambedkar’s rington, Ahmednagar, Maharashtra State 
        `}
      metadata={"16 May 2015 • Ahmednagar Maharashtra • Compiled By ACJP "}
    >
      <CaseText>
        <br />
        Mr. Sagar Shejwal a student of nursing college was hit with a beer bottle and later lynched  by four high caste youths in beer bar on 16May 2015 afternoon at Shirdi, Ahmednagar district, Maharashtra state for refusing to stop Dr. Babasaheb Ambedkar’s ringtone in his mobile. He was at Shirdi town to attend to a wedding and was sitting in the beer bar listening Dr. Babasaheb Ambedkar’s song on his mobile. The accused asked him to stop the ringtone where the victim had argument with the upper caste boys.
        <br/><br/>
        After hitting by beer bottle all of the accused took Sagar to a nearby forest and lynched him beyond recognition by running over their motorcycle on the dead body of Mr. Sagar Shejwal.
        <br/><br/>
        All the eight  accused were arrested.
        <br/><br/>
        The matter is in the court for trial.<br/><br/>
      </CaseText>
      <br />
      <CaseText>
        You can find the original article below:
        <br />
      </CaseText>
      
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://scroll.in/article/729270/dalit-man-in-ahmednagar-killed-for-ringtone-praising-ambedkar"
        size="small"
      />
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.thehindu.com/news/national/other-states/dalit-youth-killed-for-ambedkar-song-ringtone/article7232259.ece"
        size="small"
      />
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://indianexpress.com/article/india/india-others/dalit-youth-brutally-killed-in-maharashtra-for-saving-ambedkar-song-as-ringtone/"
        size="small"
      />
      <div style={{ height: "24px" }} />
    </MediumContainer>
  </Layout>
);
export default Case;
