import React from "react";
import { Carousel } from "antd";
import "./carousel.css";
import {navigate} from 'gatsby';
import case2Img from "../../images/case2_529.jpeg";

import {
  CarouselItem,
  StyledCarouselImage,
  StyledHeadline,
  Links
} from "./CarouselStyle";
import { StyledLargePrimaryButton } from "./../../styles/commonStyle";

const Articles = [
  {
    headline: <div>Indian woman from scheduled caste community dies after  gang-rape, and assault in Hathras, UP </div>,
    subHeader: "Courtesy: CNN",
    buttonText: "View Case",
    caseurl: "/case1",
    url: "https://cdn.cnn.com/cnnnext/dam/assets/200929214839-02-gang-rape-protest-0929-restricted-exlarge-169.jpg"
  },
  {
    headline: <div>Mother shot dead while preventing abduction of the daughter in Ahmednagar, MH</div>,
    subHeader: "Followed By ACJP",
    buttonText: "View Case",
    caseurl: "/case2",
    url: case2Img
  },
  {
    headline: <div>Dalit Woman and son, stripped, paraded naked through village, get Justice after 14 long years, Solapur, MH</div>,
    subHeader: "Followed By ACJP",
    buttonText: "View Case",
    caseurl: "/case3",
    url: "https://images.hindustantimes.com/rf/image_size_630x354/HT/p2/2018/06/14/Pictures/students-union-from-vemula-protest-over-rohith_13390204-6fea-11e8-bbf6-b72314b60444.jpg"
  },
  {
    headline: <div>Burning alive in Kawlewada, Gondia, MH</div>,
    subHeader: "Followed By ACJP",
    buttonText: "View Case",
    caseurl: "/case4",
    url: "https://imagevars.gulfnews.com/2019/07/31/Muslim-boy-set-on-fire-dies-in-Varanasi-hospital_16c47d3a857_medium.jpg"
  },
  {
    headline: <div>Brutal murder of a school student  for falling in  love with his classmate, Ahmednagar, MH</div>,
    subHeader: "Courtesy: DNA India",
    buttonText: "View Case",
    caseurl: "/case5",
    url: "https://cdn.dnaindia.com/sites/default/files/styles/full/public/2015/07/08/353891-death.jpg"
  },
];
const HomeCarousel = () => (
  <div>
    <Carousel autoplay arrows={true} autoplaySpeed={5000}>
      {Articles.map(entry => (
        <div
          key={entry.headline}
          style={{ maxWidth: "1440px", overflow: "hidden" }}
        >
          <CarouselItem>
            <StyledHeadline>
              <h3>{entry.headline}</h3>
              <h4>{entry.subHeader}</h4>
              <StyledLargePrimaryButton onClick={()=>{goToCase(entry)}}>
                {entry.buttonText}
              </StyledLargePrimaryButton>
            </StyledHeadline>
            <StyledCarouselImage imgUrl={entry.url}></StyledCarouselImage>
          </CarouselItem>
        </div>
      ))}
    </Carousel>
  </div>
);

const goToCase = (e) => {
  navigate(e.caseurl);
};

export default HomeCarousel;
