import React from "react";

const MainImage = () => {
  return (
    <div>
      <div>
        <img
          src="https://images.unsplash.com/photo-1525026198548-4baa812f1183?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1533&q=80"
          style={{ maxHeight: "600px", objectFit: "cover" }}
          width="100%"
        />
      </div>
    </div>
  );
};

export default MainImage;
