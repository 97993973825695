import styled from "styled-components";
import theme from "./../styles/theme";

export const DonateSection = styled.div`
  padding: 5rem 0;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-align: center;
`;

export const DonateHeader = styled.div`
  font-size: 34px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 1rem;
`;
export const DonateText = styled.div`
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  max-width: 700px;
  text-align: center;
`;
