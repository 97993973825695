import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://images.news18.com/ibnlive/uploads/2020/02/dalit-man-lynched.jpg"
      }
    >
      SC Youth beaten to death
    </CaseHeader>
    <CaseOverview
      title={`
            A SC Youth beaten to death for Open defecation in upper caste farm in Villupuram District Tamilnadu

        `}
      metadata={"16 Feb 2020 • Villupuram TN • News 18 "}
    >
      <CaseText>
        <br />
        A scheduled caste youth Mr. Shaktivel, aged 24 was caste abused and
        beaten mercilessly on charges of openly defecating in the farm belonging
        to a dominant caste on 12th Feb 2020 by a mob lead by K Raja and his
        wife R Gowri.
        <br />
        <br />
      </CaseText>

      <CaseText>
        The victim’s family upon getting the news, rushed him to the hospital
        where we was announced dead on arrival. An FIR was registered against
        seven people who were arrested on under five sections, including
        punishment of murder and under prevention of Atrocities Against SC/ST
        Act. The case also gained attention through social media where Mr
        Shaktivel can be seen with his limbs tied up with a mob surrounding him.
        <br />
        <br />
      </CaseText>

      <CaseText>
        “My brother was tied up and they checked his Aadhaar card. After coming
        to know that he comes from the Dalit colony, they tied his mouth and hit
        him with a banana tree trunk…..The woman in the video could be seen
        using casteist slurs against my brother,” Deivanai (27), sister of
        Sakthivel, said in her statement to the news.
      </CaseText>
      <br />
      <CaseText>
        You can find the original articles below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://timesofindia.indiatimes.com/city/chennai/tamil-nadu-7-including-3-women-arrested-for-lynching-dalit-youth/articleshow/74151655.cms?utm_campaign=cppst&utm_medium=text&utm_source=contentofinterest"
        size="small"
      />
      <div style={{ height: "24px" }} />
      <Microlink
        url="https://www.news18.com/news/india/dalit-man-lynched-in-tamil-nadu-for-defecating-in-field-owned-by-man-from-dominant-caste-2502825.html"
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
