import React from "react";
import { Container, FlexContainer, StyledText } from "../styles/commonStyle";

const Article = [
  {
    heading: "Dalit boys beaten, caste abused, and sodomized to force EMI payment of a Motorcycle in Nagaur District of Rajasthan. Courtesy: The Print",
    subheading:
      " Two dalit scheduled caste cousins, Pannaram Nayak and Visharam Nayak, were severely beaten up, caste-abused and sodomized by an upper-caste Rajput, Hanuman Singh and six accomplices for reportedly failing to pay an EMI for their motorcycle. Singh is the owner of the automobile agency where the cousins had bought the motorcycle from on a monthly installment basis.",
    image: "https://d2c7ipcroan06u.cloudfront.net/wp-content/uploads/2020/02/IMG_3307-scaled.jpg",
  },
  {
    heading: "Lynching of a Buddhist on the ground of marrying with caste girl in Aurangabad District Maharashtra",
    subheading:
      " Authorities in Aurangabad have charged Devidas and Rohidas Devka with the murder of Bhimraj Gaikward, and for violence against the Gaikwad family on the night of March 14, 2020.  The attack, a caste-related hate crime had roots in seeking revenge over the suspicion of Bhimraj’s older brother “luring away” a girl from the Devka family. The girl had been reported missing several days before this particular incident. ",
    image: "https://images.indianexpress.com/2020/03/dalit-killed.jpg",
  },
  {
    heading: "A SC Youth beaten to death for Open defecation in upper caste farm in Villupuram District TN. Courtesy: News 18",
    subheading:
      " A scheduled caste youth Mr. Shaktivel, aged 24 was caste abused and beaten mercilessly on charges of openly defecating in the farm belonging to a dominant caste on 12th Feb 2020 by a mob lead by K Raja and his wife R Gowri. ",
    image: "https://images.news18.com/ibnlive/uploads/2020/02/dalit-man-lynched.jpg",
  },
];

const OtherCases = () => {
  return (
    <div style={{ padding: "0 0 5rem" }}>
      <Container>
        <FlexContainer top spaceBetween>
          {Article.map(entry => (
            <div
              style={{ flex: "0 0 30%", padding: "2rem 0 0" }}
              key={entry.heading}
            >
              <img src={entry.image} width="100%"  />
              <StyledText style={{ fontWeight: "600" }}>
                {entry.heading}
              </StyledText>
              <StyledText>{entry.subheading}</StyledText>
            </div>
          ))}
        </FlexContainer>
      </Container>
    </div>
  );
};

export default OtherCases;
