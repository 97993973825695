import React from "react";
import {
  Container,
  FlexContainer,
  StyledSubHeader,
  StyledText,
} from "./../styles/commonStyle";

const Article = [
  {
    heading: "WE DEVELOP LEADERS DISRUPTING POVERTY",
    subheading:
      "Locate the victims through our network, meet them and study their cases with lawyers and start proceedings. Fight for their right to get assistance(financial) as per law, next job for the victims families, line up witnesses, and build confidence for their protection."
  },
  {
    heading: "WE DEVELOP LEADERS DISRUPTING POVERTY",
    subheading:
      "Meet all Indian government officers like SP, collector, social welfare officers and discuss with them about security of victims, their help, and rehabilitation where necessary.",
  },
  {
    heading: "WE DEVELOP LEADERS DISRUPTING POVERTY",
    subheading:
      "Long-term goals are to study child slavery, forced labour, human trafficking, bonded labour, and eradication of caste based discrimination since most of them are from marginalised communities like sc/st etc. and follow these with UN which we have been doing for last 30 years.",
  },
];

const WhatWeDo = () => {
  return (
    <div style={{ padding: "5rem 0" }}>
      <Container>
        <StyledSubHeader>WHAT WE DO</StyledSubHeader>
        <br /> <br />
        <FlexContainer top spaceBetween>
          {Article.map(entry => (
            <div
              style={{ flex: "0 0 30%", padding: "2rem 0 0" }}
              key={entry.heading}
            >
              <StyledText style={{ fontWeight: "600" }}>
                {entry.heading}
              </StyledText>
              <StyledText>{entry.subheading}</StyledText>
            </div>
          ))}
        </FlexContainer>
      </Container>
    </div>
  );
};

export default WhatWeDo;
