import React from "react";
import {
  Container,
  FlexContainer,
  StyledSubHeader,
  StyledText,
} from "../styles/commonStyle";
import { StyledMainContainer, StyledTextContainer } from "./CommunityStyles";
const CommunitySpotlight = () => {
  return (
    <div>
      <Container>
        <div style={{ padding: "5rem 0" }}>
          <FlexContainer top>
            <StyledMainContainer>
              <img
                src="https://source.unsplash.com/random/640x340"
                style={{ objectFit: "cover" }}
                width="100%"
              />
            </StyledMainContainer>
            <StyledTextContainer>
              <StyledSubHeader>Community Spotlight</StyledSubHeader>
              <StyledText>
                Dream Team member, Ashok, reframed History Month for her school
                and sparked a new conversation using the hashtag #DalitHistory.
                Dream Team member, Ashok, reframed History Month for her school
                and sparked a new conversation using the hashtag #DalitHistory.
                Dream Team member, Ashok, reframed History Month for her school
                and sparked a
                <br />
              </StyledText>
            </StyledTextContainer>
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default CommunitySpotlight;
