import React from "react";
import { Timeline } from "antd";
import { Container, StyledSubHeader } from "./../styles/commonStyle";
import { StyledTimeline, StyledPara, StyledSmallPara } from "./ACJPatUNStyles";
import Timeline2019Main from "./images/timeline2019main.jpeg";
import Timeline2019 from "./images/timeline2019.jpeg";
import Timeline20192 from "./images/timeline2019-2.jpeg";
import Timeline2018 from "./images/timeline2018.jpeg";
import Timeline2017 from "./images/timeline2017.jpeg";
import Timeline2016 from "./images/timeline2016-1.jpeg";
import Timeline20162 from "./images/timeline2016-2.jpeg";
import Timeline2015 from "./images/timeline2015.jpeg";
import Timeline2014 from "./images/timeline2014.jpeg";
import TimelineMarch2012 from "./images/timelinemarch2012.jpeg";
import TimelineMay2012 from "./images/timelinemay2012.jpeg";
import Timeline2011 from "./images/timeline2011.jpeg";
import Timeline2008 from "./images/timeline2008.jpeg";
import Timeline2007 from "./images/timeline2007.jpeg";
import Timeline20051 from "./images/timeline2005-1.jpeg";
import Timeline20052 from "./images/timeline2005-2.jpeg";
import Timeline2004 from "./images/timeline2004.jpeg";
import Timeline2002 from "./images/timeline2002.jpeg";
import Timeline2001 from "./images/timeline2001.jpeg";
import Timeline2000 from "./images/timeline2000.jpeg";
import Timeline1999 from "./images/timeline1999.jpeg";
import Timeline1998 from "./images/timeline1998.jpeg";
import Timeline1997 from "./images/timeline1997.jpeg";
import Timeline1996 from "./images/timeline1996.jpeg";
import Timeline19962 from "./images/timeline1996-2.jpeg";
import Timeline1995 from "./images/timeline1995.jpeg";
import Timeline1994 from "./images/timeline1994.jpeg";
import Timeline1993 from "./images/timeline1993.jpeg";
import Timeline19932 from "./images/timeline1993-2.jpeg";
import Timeline19933 from "./images/timeline1993-3.jpeg";
import Timeline19934 from "./images/timeline1993-4.jpeg";
import Timeline1992 from "./images/timeline1992.jpeg";
import Timeline1991 from "./images/timeline1991.jpeg";
import Timeline19912 from "./images/timeline1991-2.jpeg";

const ACJPTimeline = () => {
  return (
    <Container>
      <StyledSubHeader>Timeline</StyledSubHeader>
      <Timeline mode="alternate">
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <img
              src={Timeline2019Main}
              width="100%"
              style={{ margin: "1rem 0" }}
            />

            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2019: US Congress hearing in the Sub-Committee on South Asia
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Attended US Congress hearing organised by Sub-Committee on India:
              Under , Chairmanship of Congressman Brad Sherman and the Subject
              was Kashmir Lock-down under 370, its tragic effects on Kashmir
              Population like safety, security, food,health, media etc.
            </StyledSmallPara>
            <img src={Timeline2019} width="100%" style={{ margin: "1rem 0" }} />

            <StyledSmallPara>
              Muslim participants came from all over US and some Sikhs and acjp,
              on behalf of Dalits were present. 6 Congressmen including Madam
              JAYPAL, showed great concern about Kashmiri Lockout , no
              communication with outside world. Later I had meeting with Brad
              Sherman’s Chief of the staff and discussed the pathetic condition
              of 300 million Dalits and Tribals. Atrocities on them being
              doubled since BJP rule 2014.
            </StyledSmallPara>
            <img
              src={Timeline20192}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <StyledSmallPara>
              Memorandum was submitted to his office. committee. Dalit problem
              being 10x bigger, the Committee needed to hold next hearing in “
              Human Rights Violations of Dalits in South Asia”. My Congressman’s
              Office fully supported our stand.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2018: UNHCHR Geneva:
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in UNHCHR Geneva ,in the forum for Migrant workers
              and made statement in the UN about the 40,000 + Dalit Namshudras
              Rufugees from west Bengal coming from Bangladesh who were directed
              by CM Jyoti Basu to settle in the Dandakaranya area and later on
              26 January 1979, ordered ban on food supply to these people who
              revolted against this, and he sent 27 police vessels with guns and
              40,000 plus refugees children, women and men were killed.
            </StyledSmallPara>
            <img src={Timeline2018} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara>
              It is a historical fact that Nehru and Mahatma Gandhi had both
              assured people during the partition that if east bengal people
              were forced to join Muslim BanglaDesh, they coupld return back if
              they wanted to . Mostly they were Namshudras, the Scheduled Caste
              of Bengal. CM even prohibited media and parliamentary committee to
              investigate the massacre. We requested UN to investigate the facts
              and punish the guilty for this genocide as per international laws.
              (Refer Book-Blood Island by Deep Haldar, the oral history of the
              Marichjhapi Massacre-2019)
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2017: September UPR3 Discussion on India at Geneva Attended.
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              India implemented very few recommendations , said Human Rights
              Watch ACJP lobbied with Norway, Denmark, and Germany for Atrocity
              Act violations.
            </StyledSmallPara>
            <img src={Timeline2017} width="100%" style={{ margin: "1rem 0" }} />
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              July 2016: UN conference on Role of world governments in the field
              of Human Rights
            </StyledPara>
            <img src={Timeline2016} width="100%" style={{ margin: "1rem 0" }} />
            <img
              src={Timeline20162}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in United Nation Conference at New York on the role
              of world governments in the field of Human Rights and discussed
              with UN senior officials about the challenges, UN faces to
              eradicate caste based discrimination.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2015: Global conference on Trafficking of the girls and women
              globally
            </StyledPara>
            <img src={Timeline2015} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated at New york in the global conference on Trafficking
              of the girls and women globally and met the Special Rapporteur on
              Trafficking, to discuss the challenges in eradicating the
              Trafficking of girls and women in South Asia which is a very
              serious matter for India has 17% of global population and is
              responsible for 50% of Trafficking of girls and women in the world
              and she mentioned that India refused her request 6 times to visit
              India.
            </StyledSmallPara>
            <br />
            <StyledSmallPara>
              Participated in CEDAW at Geneva, discussed the India report and
              submitted ACJP alternative report on Eradication of discrimination
              against women and girls.{" "}
            </StyledSmallPara>
            <br />
            <StyledSmallPara>
              Participated in Committee on the Rights of Child (CRC) during the
              India report discussion and submitted the ACJP alternative report
              on child rights.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>

        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <img src={Timeline2014} width="100%" style={{ margin: "1rem 0" }} />
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              July 2014: Special meeting with Madam Navnethem Pillay in Geneva
              on Caste based discrimination
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Had a special meeting with Madam Navnethem Pillay in Geneva to
              discuss the caste based discrimination and what UN can do to help
              India to eradicate it, like it had helped to eradicate Apartheid
              of South Africa, as India is sitting on a time bomb of social
              unrest which may explode into a full-fledged civil war if
              unheeded.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              She is a granddaughter of indentured labour brought by the British
              to South Africa and she showed lot of empathy but India did not
              allow her to open the branch of UN HRDivision.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              January 2013:
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Helped National Human Rights Commission to host Two Days camp in
              Nagpur and deal with 155 Human Rights violation cases of SC and ST
              in Maharashtra region which failed to get justice. Camp was very
              successful under the supervision of Chairman of NHRC, Justice
              Balakrishnan, CJ,(rtd) Supreme Court of India.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <img
              src={TimelineMarch2012}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              March 2012
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Attended pre-session organized by UPR 2 Info, a global advocacy
              organization in Geneva, on India report discussion in 2nd cycle
              scheduled on May 24 2012.During pre-session, 20 countries shown
              their interest on Human Rights Situation in India. Most of the
              countries expressed their concerns on caste-based discrimination,
              child labor, bonded labor, girls trafficking, extreme poverty and
              dehumanization due to the practice of untouchability as per caste
              system.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              May 2012: Universal Periodic Review of India & European Parliament
              Visit
            </StyledPara>
            <img
              src={TimelineMay2012}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP delegation attended Universal Periodic Review of India report
              under Human Rights Council at United Nations, Geneva held on 24
              May 2012. 85 member states raised questions on various issues
              concerning human rights.185 questions were asked by 85 countries
              on various human rights violations like caste discrimination,
              untouchability, violence against women, land rights of tribal,
              poverty, destitution, child labor, bonded labor and child
              trafficking. For the first time, 11 countries posed questions on
              caste based human rights issues.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              On 17th of September 2012, India accepted 79 recommendations
              including monitoring mechanism in the field of human rights.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              In September 2012 ACJP did lobbying at the European Parliament
              during the debate on free trade pact between EU and India. Mr.
              Varhade had three meetings with the Deputy of distinguished
              Members of E. Parliament namely 1) Barabara Lochbihler, Chair
              Sub-committee on Human Rights, (Germany), 2) Dr. Syed Kamall, (UK)
              3) Vice Chair, EUP, Mr. Edward McMillan (UK), and discussed urgent
              need to address Child Labor, destitution and dehumanization due to
              caste system imposed on 250 million SC/STs in India.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2011: UPR2 Prepcom meet in Geneva
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              It was the Universal Periodic Review 2 of India. We presented the
              paper on atrocity, child labor, bonded labor, devdasi system
              exploitation of little girls.and met many UN officials.
            </StyledSmallPara>
            <img src={Timeline2011} width="100%" style={{ margin: "1rem 0" }} />
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2010: UN-Millennium Development Goals at NY
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in UN, Millennium Development Goals Conference and
              did interventions in Child Labor seminar in New York organized by
              UNICEF. Brazil set up examples for the world to improve Human
              Development Index for the Brazilians and also in the development
              in the field of women and children. The honorable minister for
              social justice had extended the invitation to visit Brazil for
              observation. The plenary situation of MDG had a very interactive
              session on the development of women.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2009: Durban Review Conference, HRC-Geneva & IHEU at London
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - In April 2009 ACJP participated in the Durban Review Conference
              which was a follow up to World Conference Against Racism(WCAR)
              racial discrimination & xenophobia held in Durban,2001.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              IHEU held an international convention and ACJP was invited to
              speak on Dalit issue. IHEU passed resolution to urge UNHRD to
              discuss these issues.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP delivered a shor t speech in the Human rights Council
              debate on caste based discrimination and its impact in south Asia
              which was telecasted by HRC. <br />
              <br />- ACJP was granted a special meeting with Madam Pillay, UN
              high commissioner for human rights and Madam Pillay was briefed on
              pioneering work of ACJP since 1991 due to which United Nation
              started debating on caste based discrimination. <br />
              <br /> - ACJP was invited and participated in International
              Humanist Ethical Union (IHEU) conference in June 2009 at London
              and presented the dehumanizing effect of caste system and other
              negative impacts on a quarter billion population of India. <br />
              <br />- A resolution was passed urging United Nation to eradicate
              the hidden Apartheid of South Asia as soon as possible.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2008: UN Committee on Economic, Social & Cultural Rights-Geneva &
              EU visit and Foreign Relations Committee of USA
            </StyledPara>
            <img src={Timeline2008} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Participated in UN Committee on Economic, Social and Cultural
              Rights during India Report Discussion in the month of May and
              submitted memorandum to the committee on Economic Social Cultural
              Rights(ESCR) on the issues of Dalits and how India failed to abide
              by the UN convention on ESCR for the marginalized.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Met and discussed with WHO and ILO officials in Geneva on SC/ST
              issues.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Discussion held with Director of the Human Rights division of
              European Union and briefed him on atrocities committed on SC/STs,
              3-6% conviction rate of the perpetrators and practice of
              untouchability in full force in rural India, destitution and
              dehumanization of this 250 million SC/ST including half of the
              world child labors, bonded labor and child prostitution (Devadasi
              System). It is good news that EU passed the first resolution to
              condemn the caste system and asked India to take a proactive stand
              not long ago.
              <br />
              <br />- ACJP also conducted meetings with EU MPs and discussed the
              current situation of SC/STs in India including caste system and
              child labor and asked MPs to discuss with India to take a
              proactive stand.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2007: CERD (Geneva) and UNESCO (Paris)
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Attended UN Committee on Elimination of Racial Discrimination
              (CERD) in Geneva during India Report Discussion and presented the
              problems of caste discrimination affecting more than 250 millions
              SC/ STs including 100 million child labor in schools, forcing
              untouchables to do unhygienic jobs like cleaning latrines and
              carrying dead animals for their basic survival. ACJP also
              discussed latest Human Rights violation like Khairlanji lynching
              of a dalit family. ACJP submitted the report of nodal officer of
              Maharashtra concerning Khairlanji Report with recommendations,
              which were discussed extensively in the CERD committee.
            </StyledSmallPara>
            <img src={Timeline2007} width="100%" style={{ margin: "1rem 0" }} />
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP also recommended to CERD to bring in their final
              recommendations the need for separate settlement for dalits in the
              atrocity prone areas effective immediately to prevent similar
              incidents in the future.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Attended Pre-session of UN Committee on Economic, Social and
              Cultural Rights Working Group Discussion on India and submitted
              recommendations for 2008 CESCR meeting.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2006: California School Board on Indian History & NY celebration
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP participated through its members in the California School
              Board debate on Indian History education about caste system in the
              Hindu religion. After a hot debate ACJP delegates along with other
              proactive members succeeded in convincing the board members that
              the present education on the caste system in Hindu religion was
              accurate and should be continued in the history books.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP celebrated Dr. Babasaheb Ambedkar’s birth Anniversary in
              Columbia University and distinguished Colombia professors
              categorically stated that Dr. Ambedkar’s scholarship and
              contribution to build a new India was far superior than any other
              Indian including Mahatma Gandhi.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2005: World Summit on Information Society-Tunisia
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP was invited and participated in UN World Summit on
              Information Society (WSIS) at Tunis. NGO forums around the world
              joined hands together and passed a resolution which became part of
              the final document of declaration regarding the right of every
              child to have free access to IT. “It is the Human Right of every
              child to have free access to Information Technology” [November
              (16-18), 2005].
            </StyledSmallPara>
            <img
              src={Timeline20051}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              In spite of the best Constitution in the world, and all the
              protective laws for the Scheduled Castes and Scheduled Tribes
              (Dalits and Tribals), the discrimination ,destitution and
              dehumanisation of these 300 million people is continued due to
              misguided psyche of high caste and low caste mentality and there
              is no end in sight. India has 17% of global population and carries
              the burden of half of the world’s child labour and bonded labour
              as well as 50% of Trafficking of girls and women, who are mostly
              from Dalits and Tribals. There is no serious attempt to enforce
              the laws because the will of the government has been missing. If
              the government does not take it seriously, it will push India to
              civil war as it happened in US and other countries.
            </StyledSmallPara>
            <img
              src={Timeline20052}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <br />
            <StyledSmallPara>
              Mr. Soli Sorabji met in 2002 during CERD Thematic Discussion and
              was representing Government India . As a member of the Parsi
              community , the minority population, he is quite aware of the
              problems faced by other minorities.{" "}
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2004: Human Rights Consultations-Canada
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP participated in Ottawa, Canada for Human Rights
              Consultations organized by Ministry of Foreign Affairs of Canada
              and caste system was discussed in the meeting.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Participated in Toronto Social Forum of NGOs where CNN & 60
              minutes documentary “The Untouchables” (Produced and aired in 1999
              for which ACJP helped CNN) was shown and discussed, was shocking
              for the Canadians that, the practice of untouchability still alive
              and thriving in India.
            </StyledSmallPara>
            <img src={Timeline2004} width="100%" style={{ margin: "1rem 0" }} />
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2003: World Bank-Washington DC
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in World Bank Watchdog meeting at Washington DC in
              which Mr. Montek Singh Ahluwalia was one of the key speakers along
              with other world bank officials. ACJP raised the questions of the
              destitution of the SC/STs in India and some of the senior World
              Bank Watchdog members from Ghana etc. supported ACJP stand.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2002: Working Group on Indigenous Population, CERD –Geneva,
              UNESCO-Paris,
            </StyledPara>
            <img src={Timeline2002} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Participated in UN Working Group on Indigenous Population
              (WGIP). It is important to note that ACJP has been active in WGIP
              since 1991. “Dalits and Tribals are indigenous Peoples of India.”
              was the theme paper submitted to UNWGIP.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP also participated in Committee on Elimination of Racial
              Discrimination (CERD), “Thematic Discussion on Descent- based -
              Discrimination”. UN reiterated that Caste bases discrimination is
              nothing but, discrimination based on descent and well within the
              definition of Article 1 of the International Convention on the
              elimination of all Forms of Racial Discrimination., and CERD gave
              many important recommendations to the Indian Government.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP delegation met the Deputy Director General of UNESCO ,
              Paris, who recently declared that “The BouddhaGaya Temple, where
              Lord Buddha was ENLIGHTENED, as WORLD HERITAGE SIGHT.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - In Paris along with Ven. Arya Nagarjuna Shurei Sasai who
              presented all historical documents on the claim of Buddhists of
              India on Buddhagaya Mahavihara in India (Aug 2002).This was later
              to be followed in India to support the work of UNESCO.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP delegation participated in the Asian Civil Society Forum
              held at Bangkok in Thailand and highlighted the caste
              discrimination and gross violation of human rights of 250 million
              SC/ST community in India. (Dec 2002)
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Meeting was held with the Director of South Asia Division of
              Human Rights Commission to discuss the caste issue in India.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2001: World Conference Against Racism (WCAR) Durban, South Africa
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP was accredited and ACJP delegation had participated very
              effectively in the World Conference against Racism, Racial
              Discrimination and Xenophobia held at Durban, South Africa, and
              lobbied very hard through all media communications including radio
              and TV interviews on the issue of caste system and practice of
              untouchability, child labor, child prostitution (devadasi system),
              bonded labor and the role of civil society members participating
              in Durban conference. The global media had shown great compassion
              and interest on this issue. India blocked the earlier resolution
              pro Dalit passed in the PrepCom by Switzerland.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - It is important to note that it was the pioneering efforts of
              ACJP since 1991, and specially 1996 during CERD India Report
              discussion, which triggered the debate of caste system in United
              Nations and brought it to Durban global conference. Smita Narula
              from Human Rights Watch confirmed it.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Many western democracies like Canada, Norway, Holland, Sweden,
              and Switzerland had taken keen interest to promote the interests
              of Dalits. ACJP lobbied very hard to get approved article 73 of
              Durban Declaration, which was originally proposed by Swiss and
              approved by UN at Prepcom3 at Geneva and which protected and
              promoted the interests of SC/STs. It was a global event where
              about 40000 delegates from all over the world participated.
            </StyledSmallPara>
            <img src={Timeline2001} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - It is worthy to note that National Human Rights Commission
              (NHRC) delegation headed by Justice Verma and Justice Dr. K.
              Ramaswamy played an important part at Durban by making a
              statement, which admitted that the caste system and practice of
              untouchability are thriving in India and Indian Government had
              been working very hard through its constitution to remedy the ills
              of the society like caste system.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP delegation met Madam Mary Robinson, UN High Commissioner on
              Human Rights and requested her support on SC/ST issue. (Aug-Sept
              2001)
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              2000: UN Committee on Rights of Child Geneva, WCAR Prepcom
            </StyledPara>
            <img src={Timeline2000} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Participated in UN Committee on Rights of the Child (CRC) in
              Geneva during India State Party Report discussion. Distinguished
              experts of the committee accepted most of the recommendations of
              ACJP to be a part of concluding observations. (Summary records
              589, 590 and 591-discussion on caste, child labor, illiteracy,
              bonded labor, devadasi and practice of untouchability. (Jan.2000)
              Due to the extensive lobbying of ACJP, the right to education for
              every child below 14, eradication of the child prostitution
              (Devdasi System), elimination of discrimination of Dalit children
              in the rural India, right to shelter and food, and good health,
              submitting disaggregated data, were emphasized in the concluding
              observations by the distinguished experts.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Participated in Prepcom on World Conference against Racism
              (WCAR) in Geneva and highlighted the woes of 250 million SC/ST
              community, their destitution, deprivation and discrimination,
              lobbied for bringing the issue of caste at Durban WCAR in 2001.
              (May 2000)
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Mr. Varhade lobbied Senator Moynihan, close friend of President
              Bill Clinton for his forthcoming trip to India, to raise the issue
              of caste system, practice of untouchability, child labor, bonded
              labor and child prostitution (devadasi system), to raise with the
              Indian Prime Minister, which President Clinton did talk during his
              speeches in India as the first US president raising these issues
              in India.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1999: UN NGO Committee on Rights of Child & WHO-Geneva
            </StyledPara>
            <img src={Timeline1999} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Participated in UN-NGO Committee on the Rights of Child held in
              Geneva during State party India report discussion and raised the
              plight of 110 million child labor and child prostitution (devadasi
              system) mostly from SC/ST community (HRW report 1996) and
              submitted recommendations for eradication of child labor, bonded
              labor and devadasi system. (June 1999)
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Met World Health Organization(WHO) Deputy Director General of
              Dr. Bruntland and narrated the violation of health rights of more
              than 250 million untouchable and tribal. Shown him CNN-CBS 60
              Minutes documentary ‘THE UNTOUCHABLES’ on the actual practice of
              caste system and untouchability in India.
            </StyledSmallPara>
            <img src={Timeline2000} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - ACJP delegation met Canadian Foreign Minister Mr. Lioyd Axworthy
              in Ottawa, Canada and narrated the problems of 250 million Dalits
              in India who assured to look into the problems seriously.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1998: UN Commission on Social Development-NY & Conference on Caste
              System, Toronto
            </StyledPara>

            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              -Participated in the UN Commission on Social Development
              (eradication of poverty) and lobbied for the attention of the
              commission members to address the marginalization and destitution
              of quarter billion population of Dalits in India.
            </StyledSmallPara>
            <img src={Timeline1998} width="100%" style={{ margin: "1rem 0" }} />
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              - Hosted a Conference in Toronto, Canada on Caste System, Practice
              of Untouchability and role of United Nations and Civil Society and
              sensitized participants on the issues of SC/ST in India. The
              distinguished UN expert of the committee on Human Rights, Mr.
              Martin Sheinin of Finland, delivered the keynote address in which
              he said that human rights violations like these should be
              addressed through different UN forums using different UN
              mechanisms which will help to create peace and justice in the
              civil society. Prof. Sam Lanfranco, Chair of the Deptt. of
              Economics, York University, Toronto, Canada and advisor to ACJP
              stressed the need for global community to be involved in this
              issue. Ms. Jane Jacobs, the globally recognized distinguished
              urban historian also spoke on the plight of the marginalized in
              India and asked the media to play important role to educate the
              civil society so, that it became a global challenge. Dr. Chris
              Queen from Harvard University, spoke on Engaged Buddhism and Dr.
              Ambedkar’s role in promoting peace and justice in India.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1997: UN Committee on Civil and Political Rights-Geneva
            </StyledPara>
            <img src={Timeline1997} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP was invited and participated in the UN Committee on Civil &
              Political Rights during State Party India Report discussion. ACJP
              presented the paper explaining how the civil and political rights
              of SC/STs were violated regularly and gave many recommendations to
              improve the situations. Most of ACJP’s recommendations were
              included in the Concluding Observations. Detailed discussions are
              available in summary records 1603, 1604 & 1606 on UN website.
            </StyledSmallPara>

            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Canadian TV Ontario made a documentary on ACJP contribution, to
              break the caste system through United Nations and Civil society,
              which was released on 2nd Jan, 97.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Special Rapporteur on Racism, Racial Discrimination and Xenophobia
              of United Nations Mr. Glele –Ahanhonzo was briefed on the problems
              of caste atrocities and untouchability in India for which, he
              sought permission to visit India but Indian government had denied.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1996: UN CERD Committee Geneva & Canadian parliament
            </StyledPara>
            <img src={Timeline1996} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP was invited by the Canadian Parliamentary Committee to
              participate in the debate on Child Labor and bonded labor and
              child prostitution in India and methods for their eradication.
              After ACJP presentation, Canadian Member of Parliament, Mr. Jessie
              Fliss, Toronto, supported whole heartedly the efforts of ACJP to
              eradicate the child labor and caste system in India.
            </StyledSmallPara>

            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP was invited to participate in the UN Committee on Elimination
              of Racial Discrimination (CERD) during State Party India report
              discussion. This was the first-time treaty body of United Nations
              discussed in detail the practice of caste system and
              untouchability and ruled that caste discrimination is same as
              descent based discrimination as per article 1 of the convention on
              Elimination of all forms of Racial Discrimination. The committee
              had shown great compassion and empathy against the sufferings of
              the SC/STs and accepted all recommendations of ACJP to prevent
              caste discrimination and caste atrocities.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              It is important to note that CERD gave very valuable
              recommendation in its Concluding Observations (CERD/C/304/Add.13
              dated 17/09/96 article 31) which states that ‘the committee
              recommends a continuing campaign to educate Indian population on
              Human Rights, in line with the constitution of India & universal
              Human Rights Instruments, including the International Convention
              of All Forms of Racial Discrimination. This should be aimed at
              eliminating the institutionalized thinking of the high caste and
              low caste mentality’. Constitution of India with Discussion are
              available in summary records No.1161, 1162 & 1163. It was
              pioneering and historical contribution of ACJP.
            </StyledSmallPara>
            <img
              src={Timeline19962}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1995: World Summit on Social Development-Copenhagen
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP was invited and participated in the World Summit on Social
              Development (WSSD) (Eradication of Poverty) in Copenhagen,
              Denmark. ACJP highlighted the destitution of 250 million
              untouchables and tribals due to the caste system. An article in
              Denmark’s newspaper POLITIKEN appeared on 7th March 1995 on the
              problems of SC/ST which brought many Danish intellectuals to ACJP
              booth showing their solidarity with dalits. Swiss delegate Mr. J.
              F. Giovanni addressed caste system in his presentation at the
              summit.
            </StyledSmallPara>
            <img src={Timeline1995} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              World Conference on Women’s Rights held in Beijing, China where
              Prof. Ana Maria Quiroz from Chile, ACJP advisor, represented dalit
              women issue and in collaboration with dalit women delegation from
              India jointly prepared the Beijing statement on problems of
              discrimination on SC/ST women of India.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1994: UN Sub-commission on Prevention of Discrimination of
              Minorities, Geneva & UN WGIP
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in the UN Working Group on Indigenous People (WGIP)
              and proposed the creation of human rights monitoring system within
              the mandate of WGIP for all indigenous peoples of the world
              including Dalits and Tribals in India.
            </StyledSmallPara>
            <img src={Timeline1994} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in the UN Sub-commission on Prevention of
              Discrimination and Protection of Minorities and highlighted the
              problems of SC/ST as minorities in India specially the child
              labor.
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Met foreign governments of Canada, USA, Holland, Norway, Germany,
              and Sweden, briefed them on the Human Rights violations of SC/ST
              communities.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1993: UN World Conference on Human Rights Vienna & WCHR
              Prepcom-Bangkok & UNESCO Conference -Montreal
            </StyledPara>
            <img src={Timeline1993} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP was invited and participated in UN World Conference on Human
              Rights at Vienna, Austria (June 1993). Due to ACJP’s extensive
              lobbying, a separate workshop on “Caste system, practice of
              untouchability, bonded labor, and United Nation’s role” was
              accepted for the first time in UN world conference. The resolution
              was passed in the workshop under the chairmanship of Ramos Horta
              (later Became the noble laureate from East Timore) and presented
              to the plenary session which approved it and was circulated. ACJP
              booth was visited by many European MPs and they signed the
              petition for supporting the Human Rights of SC/STs of India.
            </StyledSmallPara>
            <img
              src={Timeline19932}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP participated in Bangkok Prepcom of UN world Conference on
              Human Rights and succeeded in lobbying for important NGO
              declaration statement in favor of Dalits which was approved by
              majority NGOs from Asia and Pacific region “to eradicate the
              practice of untouchability, a crime against humanity, within
              period of ten years or the countries should face sanctions”.
            </StyledSmallPara>
            <img
              src={Timeline19933}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Attended Canadian Human Rights Conference organized by Canadian
              Government for the preparation of PREPCOM and asked Canadian
              delegates to work for dismantling the caste apartheid in South
              Asia.
            </StyledSmallPara>
            <img
              src={Timeline19934}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Attended UNESCO conference at Montreal and made many interventions
              on the issue of Dalits highlighting their plights.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1992: UN Working Group on Indigenous peoples- Geneva
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in the Working Group on Indigenous Population (WGIP)
              in Geneva and submitted documentation on indigenous background of
              Dalits as well as highlighted their human rights violations.
            </StyledSmallPara>
            <img src={Timeline1992} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              It was interesting to note that India did not like the study of
              Tribals and Dalits (ST/ST) to be included in the report of GLOBAL
              INDIGENOUS PEOPLES which was originally prepared by Prof.
              Williamson Diaz whose efforts helped to get started Working Group
              on Indigenous Peoples in UN, which in 1993 resulted in bringing
              all Indigenous Peoples of the world under one banner of UN and
              later on in 1993 UN Declaration on Indigenous Peoples passed by
              General Assembly came and Permanent Forum for indigenous People
              has been created
            </StyledSmallPara>
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Participated in the Dalit Conference organized by Swiss Church Aid
              Group at Basel, Switzerland in June and submitted the Dalit
              perspective for the liberation of Dalits.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
        <Timeline.Item color="green">
          <StyledTimeline style={{ marginRight: "0" }}>
            <StyledPara style={{ color: "#4a4a4a", fontWeight: "600" }}>
              1991: UN Working Group on Indigenous population -Geneva
            </StyledPara>
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              ACJP participated for the first time in the UN Working Group on
              Indigenous Population and did interventions to highlight the issue
              of untouchability and caste discrimination on 250 million SC/STs
              indigenous peoples of India. Many indigenous participants were
              surprised by the information, since it was for the first time that
              the issue of Dalits as indigenous peoples was brought to their
              attention and the indigenous population was so high.
            </StyledSmallPara>
            <img src={Timeline1991} width="100%" style={{ margin: "1rem 0" }} />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Hosted Dr. Babasaheb Ambedkar Birth Centenary Celebration and
              Human Rights Conference in Toronto Canada in June 1991.Mr Elijah
              Harper the great leader of Canadian Indigenous Peoples was the
              keynote speaker.
            </StyledSmallPara>
            <img
              src={Timeline19912}
              width="100%"
              style={{ margin: "1rem 0" }}
            />
            <br />
            <StyledSmallPara style={{ color: "#4a4a4a" }}>
              Mr. Yogesh Varhade and Mr. P. N. Arya along with other colleagues
              from VISION group coordinated the historic celebration of Dr.
              Ambedkar’s Birth Anniversary at Columbia University with half a
              day Human Rights Conference and half a day cultural programs.
              Indian Ambassador in US, Dr. Abid Hussain was the chief guest.
              Human Rights Conference was attended by most of the American
              scholars, who had done studies of Dalits and the caste system, and
              wrote extensively on the issues of Dalits. It was a great cultural
              celebration of dance and drama. 450 delegates from all over US
              came to participate in the event. It was the biggest south Asian
              Celebrations in the history of Columbia University, we were told.
            </StyledSmallPara>
          </StyledTimeline>
        </Timeline.Item>
      </Timeline>
    </Container>
  );
};

export default ACJPTimeline;
