import React from "react";
import { DonateSection, DonateHeader, DonateText } from "./DonateSectionStyles";
import {
  StyledLargeSecondaryButton,
  Container,
  FlexContainer,
} from "./../styles/commonStyle";
const Donate = () => {
  return (
    <DonateSection>
      <Container>
        <FlexContainer center>
          <div>
            <DonateHeader>DONATE TO PROTECT HUMAN RIGHTS</DonateHeader>
            <DonateText>
              Together we can fight for human rights everywhere. We can stop
              torture, demand equality for people, and give hope to those facing
              attacks just because of what their caste is. Your donation can
              transform the lives of millions.
            </DonateText>
            <FlexContainer center style={{ padding: "1.5rem 0" }}>
              <a
                className="custom-dbox-popup"
                href="https://donorbox.org/testing-widget"
              >
                <StyledLargeSecondaryButton>
                  DONATE NOW
                </StyledLargeSecondaryButton>
              </a>
            </FlexContainer>
          </div>
        </FlexContainer>
      </Container>
    </DonateSection>
  );
};
export default Donate;
