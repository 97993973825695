import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import Microlink from "@microlink/react";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://cdn.dribbble.com/users/1303437/screenshots/4952713/attachments/1110126/abstract-loader-white.gif"
      }
    >
      Lynching of a Buddhist teen
    </CaseHeader>
    <CaseOverview
      title={`
            Lynching of a Buddhist on the ground of marrying with caste girl in Aurangabad District Maharashtra.
        `}
      metadata={"14 March 2020 • Aurangabad Maharashtra • Compiled By ACJP  "}
    >
      <CaseText>
        <br />
        Authorities in Aurangabad have charged Devidas and Rohidas Devka with
        the murder of Bhimraj Gaikward, and for violence against the Gaikwad
        family on the night of March 14, 2020. The attack , a caste-related hate
        crime had roots in seeking revenge over the suspicion of Bhimraj’s older
        brother “luring away” a girl from the Devka family. The girl had been
        reported missing several days before this particular incident.
        <br />
        <br />
      </CaseText>

      <CaseText>
        The victim Mr Bhimraj, a teenager was beheaded with reports of his
        parents, Mr Balasaheb and Mrs. Alka, being subject to attacks with sharp
        weapons. They were moved to a government hospital where their treatment
        and recovery is being monitored. This incident took place in the Lakh
        Village of Vaijapur Taluke in the Aurangabad district. As disturbing and
        disconcerting as these incidents might sound, they seem to follow a
        common pattern in various parts of India where people from a higher
        caste wanting to espouse someone from a lower caste have to encounter
        honor killings.
        <br />
        <br />
      </CaseText>

      <CaseText>
        The National Crime Record Bureau annually records several such cases.
      </CaseText>
      <CaseText>
        You can find the original article below:
        <br />
      </CaseText>
    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    >
      <Microlink
        url="https://indianexpress.com/article/india/days-after-girl-goes-missing-dalit-teen-beheaded-in-maharashtra-village-6317611/"
        size="small"
      />
    </MediumContainer>
  </Layout>
);
export default Case;
