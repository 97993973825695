import React from "react";
import Layout from "../components/Layout/Layout";
import CaseHeader from "../components/Cases/CaseHeader";
import CaseOverview from "../components/Cases/CaseOverview";
import { CaseText, MediumContainer } from "../components/Cases/CasesStyles";

const Case = () => (
  <Layout>
    <CaseHeader
      image={
        "https://imagevars.gulfnews.com/2019/07/31/Muslim-boy-set-on-fire-dies-in-Varanasi-hospital_16c47d3a857_medium.jpg"
      }
    >
      Burning alive in Kawlewada, Gondia, MH| Tag (News) 
    </CaseHeader>
    <CaseOverview
      title={`
        Burning alive in Kawlewada, Gondia, MH| Tag (News)
        `}
      metadata={
        "17 May 2014 • Gondia Maharashtra • Compiled By ACJP "
      }
    >
      <CaseText>
        <br />
        On 17th May 2014-Kawlewada,Gondia District of Maharashtra State, Mr Sanjay Khobragade(age 50) was burnt alive while asleep by sprinkling petrol by six upper caste men and women who were  powerful village politician  in the midnight on 17th May 2014 because Mr.Khobragade,a human rights village activist was in the forefront on behalf of Buddhist Community(formerly untouchables- scheduled caste)demanding a piece of government land for construction of a prayer hall(Buddhavihara) with the sarpanch(village head) and other upper castes from the village.
Devkabai, wife of Mr.Khobragade doused flames immediately by pouring cold water on the burning Khobragade and rushed him to the nearest city government hospital with 90% burn.
        <br />
        <br />
The police registered the first information report(FIR) no.38/14 under various sections of IPC and the Scheduled Caste and Scheduled Tribes Prevention of Atrocities Act-1989. The victim Mr Khobragade named six persons in the FIR registered the same day in the hospital and dying declaration which is video recorded on the same day when brought for better treatment at Nagpur Medical College Hospital. Local TV channel named Buddha Channel,TV-9 and Mee Marathi carried his actual statement live on the TV news bulletin on 17th,18th and 19th May 2014.
Mr Khobragade later succumbed to his extensive burn 90% and died in the hospital on 23rd May 2014.
        <br />
        <br />
In order to save high caste mighty, wealthy and politically connected accused, police fabricated a story and arrested wife of the victim, abused, beaten and tortured Mrs. Devkabai Khobragade(then aged 48 years )in illegal police custody for fabricated charges of burning and killing her husband and a poor rickshaw pooler Mr. Raju Gadpaile(then aged 40 years )falsely implicating for having affair with Mrs Khobragade and abetting the crime. While alive, the victim never said that his wife or Mr. Gadpaile is responsible for  burning him alive.
        <br />
        <br />
The court bailed out all the actual accused and arrested and put under judicial custody the wife of the deceased. Police, administration and politicians have joined hands together to cover-up this ghastly crime against humanity and all the Scheduled caste and Buddhist people are under tremendous fear for their life. 
        <br />
        <br />
      </CaseText>

    </CaseOverview>
    <MediumContainer
      style={{ padding: "0rem 0 10rem", margin: "-10rem auto 0" }}
    />
  </Layout>
);
export default Case;
