import styled from "styled-components";
import theme from "./../../styles/theme";
import { Link } from "gatsby";

export const Links = styled(Link)`
  font-size: 1rem;
  margin: 0.25rem 1.5rem;
  color: ${theme.colors.primary};
  font-weight: 500;
  text-decoration: none;
  position: relative;
`;

export const StyledCarouselImage = styled.div`
  display: flex;
  height: calc(100vh - 80px);
  width: 60%;
  align-items: center;
  justify-content: center;
  background: ${({imgUrl})=> `url(${imgUrl})`};
  background-size: cover;
  transition: 0.4s cubic-bezier(1, -1, 0, 2);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0 100%);
  position: relative;
`;

export const StyledHeadline = styled.div`
  background: ${theme.colors.white};
  padding: 2rem 5rem;
  width: 400px;
  text-align: left;
  width: 40%;

  h3 {
    font-size: 48px;
    color: ${theme.colors.primary};
  }
  h4 {
    font-size: 15px;
    color: ${theme.colors.primary};
  }
`;
export const CarouselItem = styled.div`
  display: flex !important;
  align-items: center;
`;
