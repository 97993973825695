import React from "react";
import {
  StyledFooter,
  FooterTitle,
  FooterLink,
  FooterRight,
  Brand,
  BrandSmall,
} from "./FooterStyles";
import DonateSection from "./../DonateSection/DonateSection";
import { StyledPrimaryButton, FlexContainer } from "./../styles/commonStyle";

const FooterContainer = () => (
  <>
    <DonateSection />
    <StyledFooter>
      <FlexContainer top center spaceAround>
        <div>
          <FooterTitle>ABOUT</FooterTitle>
          <FooterLink>About Us</FooterLink>
          <FooterLink>Manifesto</FooterLink>
          <FooterLink>ACJP at United Nations</FooterLink>
          <FooterLink>Community</FooterLink>
        </div>
        <div>
          <FooterTitle>REPORTS</FooterTitle>
          <FooterLink>Reports and Data</FooterLink>
          <FooterLink>Human Rights Watch</FooterLink>
        </div>
        <div>
          <FooterTitle>CASES</FooterTitle>
          <FooterLink>News and Cases</FooterLink>
          <FooterLink>Case Studies</FooterLink>
          <FooterLink>Video Interviews</FooterLink>
          <FooterLink>Monthly Bulletin</FooterLink>
        </div>

        <FooterRight>
          <Brand to="/">ACJP</Brand>
          <BrandSmall style={{ margin: "0.5rem 0" }}>
            Ambedkar Center for Justice and Peace
          </BrandSmall>
          <BrandSmall style={{ margin: "0.5rem 0" }}>
            Address: 210 Butler St
          </BrandSmall>
          <BrandSmall style={{ margin: "0.5rem 0" }}>
            Kingston PA 18704 USA
          </BrandSmall>
          <BrandSmall style={{ margin: "0.5rem 0" }}>
            Email: <a href="mailto:victoryandpeace1449@gmail.com">victoryandpeace1449@gmail.com</a>
          </BrandSmall>
          <FlexContainer style={{ padding: "1rem 0" }}>
            <StyledPrimaryButton>DONATE</StyledPrimaryButton>
            <StyledPrimaryButton style={{ marginLeft: "20px" }}>
              JOIN ACJP
            </StyledPrimaryButton>
          </FlexContainer>
        </FooterRight>
      </FlexContainer>
    </StyledFooter>
  </>
);

export default FooterContainer;
