import styled from "styled-components";
import theme from "../styles/theme";

export const DeclarationBlob = styled.div`
  margin: 10rem 5rem;
  padding: 5rem 5rem;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
`;

export const StyledMainContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;

export const StyledTextContainer = styled.div`
  flex: 0 0 50%;
  padding: 0rem 2rem;
`;

export const StyledTimeline = styled.div`
  flex: 0 0 300px;
  background: #f3f3f3;
  padding: 2rem 2rem;
  border-radius: 5px;
  margin-right: 50px;
`;
export const StyledPara = styled.div`
  font-size: 18px;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin: ${({ margin }) => margin || "0"};
  line-height: 1.75;
  font-weight: ${({ bold }) => (bold ? "600" : "400")};

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;
export const StyledSmallPara = styled.div`
  font-size: 14px;
  text-align: ${({ center }) => (center ? "center" : "left")};
  line-height: 1.6;
`;
