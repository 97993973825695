import React from "react";
import {
  Container,
  FlexContainer,
  StyledHeading,
  StyledSubHeader,
  StyledText,
} from "../styles/commonStyle";
import { StyledMainContainer, StyledTextContainer } from "./ACJPatUNStyles";
const Overview = () => {
  return (
    <div>
      <Container>
        <div style={{ padding: "0rem 0 5rem" }}>
          <FlexContainer top>
            <StyledMainContainer>
              <StyledHeading>Overview of our Work</StyledHeading>
            </StyledMainContainer>
            <StyledTextContainer>
              <StyledSubHeader>Overview</StyledSubHeader>
              <StyledText>
                ACJP (Ambedkar Center for Justice and Peace) was founded in
                1991, the year of Dr. Babasaheb Ambedkar&apos;s BIRTH CENTENARY
                by Mr. Yogesh Varhade, a businessman in North America. He
                started participating in the United Nations Human Rights
                Division, Geneva from July, 1991 onwards.
                <br />
                <br />
                <br />
                As Dr. Ambedkar said,” There is a South Africa in every village
                in India.” The caste system and practice of Untouchability is so
                deeply entrenched in rural India that even after 70 years of
                Independence a simple act of fetching water from the local well
                by a Dalit (Untouchable) is considered to be polluting. They are
                thus at the mercy of the upper caste Hindus and have to wait
                till they throw a bucket of water in their pot without any
                social interaction. Dalits are treated as untouchables from
                cradle to grave, even though it is a criminal offense prohibited
                by constitution. Their population is about 300 million, nearly
                equal to population of USA, or equal to that of UK, France, and
                Germany combined.
                <br />
                <br />
                <br />
                India has 17% of global population and accounts for nearly 50%
                of child labour, 50% of bonded labour, and 50% of trafficking
                Girls and women of the world.
                <br />
                <br />
                <br />
                Atrocities like killings, rapes, beatings, burning their homes
                etc in the name of caste/religion are on the rise.Everyday there
                are 3 dalits killed, 5 dalit girls and women raped and for every
                recorded case of rape nine go unrecorded. This is a massive
                problem which needs immediate attention of the state. India
                needs to resolve these issues if it wants to grow as peaceful,
                powerful democracy where equality, liberty, secularism,
                fraternity and social justice thrives side by side .
                <br />
                <br />
                <br />
                ACJP has attended most of the major UN human rights conferences
                globally and Treaty Bodies deliberations on state party India
                since 1991 and highlighted the massive challenge of eradication
                of caste-based discrimination in South Asia. The biggest
                challenge facing United Nations in 21st Century is the
                eradication of caste system. First we need to educate the global
                civil society who can help us when we follow the path of fall of
                South African Apartheid through United Nations.
              </StyledText>
            </StyledTextContainer>
          </FlexContainer>
        </div>
      </Container>
    </div>
  );
};

export default Overview;
